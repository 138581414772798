import * as React from "react"
import useUser from "~/hooks/useUser"
import useImages from "~/hooks/useImages"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import { ButtonLink } from "~/components/reusable/Button"
import ProfileEdit from "../../images/profile-edit.png"
import NewsEdit from "../../images/news-edit.png"
import {
  FaCheckSquare,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa"
import useTemplateContent from "~/hooks/useTemplateContent"
import ContactUsForm from "../ContactUsForm"
import PreviewImage from "../reusable/PreviewImage"
import parse from "html-react-parser"
import Seo from "../SEO"
import PostImage from "../reusable/PostImage"

export default function SupplierPortal() {
  const { user } = useUser()
  const [content, title, text, advertise, meta, contactContent] =
    useTemplateContent([
      "supplierPortal",
      "supplierPortalTitle",
      "supplierPortalText",
      "supplierPortalAdvertise",
      "supplierPortalMeta",
      "contactUs",
    ])

  const supplierLinks = [
    {
      icon: ProfileEdit,
      title: content.editProfileTitle || "Edit your Profile Page",
      description: content.editProfileText,
      link: {
        url: "/portal/suppliers/edit",
        text: "Profile Editor",
      },
    },
    {
      icon: NewsEdit,
      title: content.editNewsTitle || "Manage your News Posts",
      description: content.editNewsText,
      link: {
        url: "/portal/suppliers/posts",
        text: "News Editor",
      },
    },
  ]

  const profilePhoto = user?.linkedGroupData?.supplierDetails?.profilePicture?.mediaItemUrl

  return (
    <>
      <Seo
        title={meta?.title || `Suppliers Portal`}
        description={meta?.description}
      />
      <header
        className={`relative bg-theme-orange-lighter [&_svg]:absolute [&_svg]:bottom-0 [&_svg]:left-0 [&_svg]:right-0 [&_svg]:z-[1]`}
      >
        <Wrapper className={`justify-between md:pt-3`}>
          <div
            className={`welcome flex-set-0-0 relative z-[2] mx-auto mt-6 mb-4 basis-almost-full px-3 md:mx-0 md:mb-0 md:mt-0 md:flex-1 lg:mx-1/12`}
          >
            <h1
              className={`block text-center text-[29px] font-bold leading-none tracking-[-0.024em] md:mt-10 md:text-left`}
            >
              Welcome
            </h1>
            <p
              className={`block text-center text-[29px] leading-none tracking-[-0.024em] md:text-left`}
            >
              {user?.linkedGroupData?.title || user?.name}
            </p>
          </div>
          {profilePhoto && (
            <div
              className={`logo flex-set-0-0 z-[2] mx-auto flex h-[204px] basis-[204px] items-center rounded-full border-[1px] border-solid border-theme-gray-mid bg-theme-white p-8 md:mx-3 lg:mx-[calc(8.333%_+_12px)]`}
            >
              <PostImage
                fallbackUrl={profilePhoto}
                alt={undefined}
                className={undefined}
              />
            </div>
          )}
        </Wrapper>
        <Spacer top={`orange-lighter`} bottom={`white`} />
      </header>
      <Wrapper>
        <div
          className={`flex-set-0-0 basis-5/6 px-3 pt-10 pb-10 md:basis-3/4 md:pt-14 md:pb-28 lg:ml-1/12 lg:basis-7/12`}
        >
          <h2
            className={`mb-8 text-h2 font-bold tracking-[-0.024em] md:text-h1`}
          >
            {title}
          </h2>
          <p className={`text-normal leading-8`}>{text}</p>
        </div>

        <div className="flex-set-0-0 mx-auto mb-10 grid basis-almost-full grid-cols-1 gap-6 md:mb-20 md:grid-cols-2 lg:basis-almost-5/6">
          {supplierLinks.map((link, index) => (
            <div
              key={index}
              className={`vs-shadow rounded-[32px] bg-theme-yellow p-8 lg:py-16 lg:px-12`}
            >
              <img src={link.icon} className={`mx-auto mb-9 w-[90px]`} />
              <h2
                className={`mb-4 block max-w-[60%] px-3 text-h4 font-bold leading-none lg:text-h3`}
              >
                {link.title}
              </h2>
              <p className={`mb-6 block px-3 text-small`}>{link.description}</p>
              <ButtonLink
                buttonStyle={`primary`}
                buttonColor={`orange`}
                link={link.link.url}
                text={link.link.text}
              />
            </div>
          ))}
        </div>
      </Wrapper>
      <Spacer top={`white`} bottom={`green-lighter`} />
      <div
        className={`bg-theme-green-lighter pb-12 pt-8 md:pb-24 md:pt-16 lg:pb-32 lg:pt-24`}
      >
        <Wrapper className={`[&_.brew-button]:mx-auto [&_.brew-button]:mt-8`}>
          <h2
            className={`flex-set-0-0 mx-auto mb-6 basis-almost-full text-center text-h3 md:text-h2`}
          >
            {advertise.title}
          </h2>
          <p
            className={`flex-set-0-0 mx-auto mb-5 basis-almost-full md:basis-almost-3/4 lg:basis-almost-1/2`}
          >
            {advertise.text}
          </p>
          <div
            className={` mb-4 grid grid-cols-1 items-end md:grid-cols-2 lg:grid-cols-4`}
          >
            {advertise.packages &&
              advertise.packages.map((pricePackage, index: number) => {
                return (
                  <div
                    key={index}
                    className={`vs-shadow m-3 overflow-hidden rounded-3xl bg-theme-white leading-8 [&_.gatsby-image-wrapper-constrained]:block`}
                  >
                    {pricePackage.recommended && (
                      <span
                        className={`mt-6 block text-center text-caption font-extrabold uppercase tracking-[0.2em] text-theme-green`}
                      >
                        Recommended
                      </span>
                    )}
                    {pricePackage?.icon ? (
                      <PreviewImage
                        image={pricePackage?.icon.localFile}
                        fallbackUrl={pricePackage?.icon?.sourceUrl}
                        alt={``}
                        className={`mx-auto mt-12 mb-10 block w-[120px]`}
                      />
                    ) : null}
                    <h5
                      className={`mb-5 block px-11 text-h6 font-bold md:text-h5`}
                    >
                      {pricePackage.title}
                    </h5>
                    <ul className={`mb-5`}>
                      {pricePackage.features &&
                        pricePackage.features.map((feature, index) => {
                          return (
                            <li
                              key={index}
                              className={`flex-start mb-3 flex px-11 text-small`}
                            >
                              <i
                                className={`mr-2 h-6 w-6 min-w-[24px] text-theme-green`}
                              >
                                <FaCheckSquare />
                              </i>
                              {feature.text}
                            </li>
                          )
                        })}
                    </ul>
                    <span
                      className={`block bg-theme-green p-3 text-center font-black text-theme-black`}
                    >
                      {pricePackage.price}
                    </span>
                  </div>
                )
              })}
          </div>
          <ButtonLink
            buttonStyle={`primary`}
            buttonColor={`orange`}
            link={`#contact`}
            text={advertise.buttonLabel}
          />
        </Wrapper>
      </div>
      <div
        id="contact"
        className={`mb-[140px] bg-theme-yellow-light pt-10 md:pt-[72px]`}
      >
        <Wrapper>
          <div
            className={`flex-set-0-0 basis-full px-3 md:basis-1/2 md:pt-[60px] lg:mx-1/12 lg:basis-1/3`}
          >
            <h4 className={`mb-8 text-h3 md:text-h4 md:leading-8`}>
              {parse(contactContent.title)}
            </h4>
            <p className={`mb-6 text-normal`}>
              <i
                className={`mr-2 inline-block h-6 w-6 align-middle text-theme-orange`}
              >
                <FaPhoneAlt />
              </i>
              <a className={`font-bold`}>0800 756 6636</a>
            </p>
            <p className={`mb-6 text-normal`}>
              <i
                className={`mr-2 inline-block h-6 w-6 align-middle text-theme-orange`}
              >
                <FaEnvelope />
              </i>
              <a className={`font-bold`}>info@vetshare.co.uk</a>
            </p>
            <p className={`mb-6 text-normal`}>
              <i
                className={`mr-2 inline-block h-6 w-6 align-middle text-theme-orange`}
              >
                <FaMapMarkerAlt />
              </i>
              <span className={`inline-block w-[calc(100%_-_40px)] align-top`}>
                <strong>Vetshare,</strong> CVS House, Owen Road, Diss, Norfolk,
                IP22 4ER
              </span>
            </p>
          </div>
          <div
            className={`flex-set-0-0 vs-shadow mx-3 mb-[-72px] basis-almost-full overflow-hidden rounded-[32px] bg-theme-yellow p-12 md:basis-almost-1/2 lg:basis-almost-5/12 [&_.brew-button]:ml-auto [&_.brew-button]:mr-0 [&_.brew-button]:block`}
          >
            <h3 className={`mb-5 text-h4 font-bold md:text-h3`}>
              Get in touch
            </h3>
            <ContactUsForm formRef="contactUsFormSuppliers" />
          </div>
        </Wrapper>
      </div>
      <Spacer top={`white`} bottom={`black`} />
    </>
  )
}
