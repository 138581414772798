import React, { useCallback, useContext, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import useUser from "~/hooks/useUser"
import { restApiUrl } from "~/lib/constants"
import { createMediaFromFile } from "~/lib/uploads"
import { bytesToSize } from "~/utils/helpers"
import fileIcon from "../../images/file-icon.png"
import { FaCheck, FaTrash } from "react-icons/fa"
import { ProfileContext } from "../routes/SuppliersEdit"
import Loader from "../Loader"
import { PostContext } from "../routes/EditSupplierPost"
import TextInput from "./TextInput"
import { useUpdateSupplierNewsItemMutation } from "~/graphql/generated/graphql"
import { Slide, toast } from "react-toastify"
import { GET_SUPPLIER, GET_SUPPLIER_NEWS_ITEM } from "~/lib/queries"
import { useUpdateSupplier } from "~/hooks/supplier"
import { usePostFormStore } from "~/lib/stores/forms"

function removeDuplicates(arr: any) {
  const isArr = Array.isArray(arr)
  if (!arr || arr.length === 0 || !isArr) return
  return arr.filter((item: any, index: any) => {
    return arr.indexOf(item) >= index
  })
}

function setToArray(set: Set<any>) {
  const arr = Array.from(set)
  // check if arr is nested
  if (arr[0] && Array.isArray(arr[0])) {
    // flatten the array
    const flattened = arr.flat()
    return flattened
  }
  return arr
}

const Dropzone = ({
  name,
  example,
  limit = 1,
  value,
  formSaved,
}: {
  name: string
  value: File[]
  example?: React.ReactNode
  limit: number
  formSaved: boolean
}) => {
  const {
    user: { token },
  } = useUser()
  const [media, setMedia] = useState(() => new Set<any>())
  const [mediaTitles, setMediaTitles] = useState(() => new Set<any>())
  const [files, setFiles] = useState<File[]>([])
  const profileContext = useContext(ProfileContext)
  const postContext = useContext(PostContext)
  const [uploading, setUploading] = useState(false)
  const [preloaded, setPreloaded] = useState(false)
  const [updateSupplierNewsItemMutation] = useUpdateSupplierNewsItemMutation()
  const { updateSupplier } = useUpdateSupplier()
  const [filesUploaded, setFilesUploaded] = useState(false)

  const [originalValue, setOriginalValue] = useState<File[]>([])
  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  // handle dirty fields
  useEffect(() => {
    if (!preloaded) return

    if (files?.length > 0 && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if files match original value, remove from dirty fields
    if (files && files?.length === value?.length) {
      const filesIds = files
        .filter(
          file =>
            file && (file.databaseId || (file.file && file.file.databaseId))
        )
        .map(file => file.databaseId || file.file.databaseId)

      const originalValueIds = originalValue
        .filter(
          file =>
            file && (file.databaseId || (file.file && file.file.databaseId))
        )
        .map(file => file.databaseId || file.file.databaseId)
      const filesMatch = filesIds.every(
        (id, index) => id === originalValueIds[index]
      )
      if (filesMatch) {
        removeDirtyField(name)
      }
    } else if (files && !dirtyFields.includes(name)) {
      if (files?.length === 0) return
      addDirtyField(name)
    }
  }, [files, originalValue, preloaded])

  useEffect(() => {
    if (!originalValue.length) {
      setOriginalValue(files)
    }
  }, [files])

  // onDrop upload file in the background
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!token || !restApiUrl) return
      let newFiles: any[] = []

      const uploadedFiles = new Promise<void>((resolve, reject) => {
        acceptedFiles.forEach(async (file: any, index: number) => {
          setUploading(true)
          const res = await createMediaFromFile(file, token, restApiUrl)

          if (res.ok) {
            setUploading(false)
            const data = await res.json()
            const mediaId = data?.id
            setMedia(prev => new Set(prev).add(mediaId))

            // get file extension from mime type
            const fileExtension = data?.mime_type?.split("/")[1]

            // id to File object
            const fileObj = {
              databaseId: mediaId,
              fileSize: data.media_details.filesize || file.size,
              fileName: `${data.title.rendered}.${fileExtension}` || file.name,
            }

            newFiles.push(fileObj)
          }
          if (!res.ok) {
            setUploading(false)
            console.error("error")
          }

          if (index === acceptedFiles.length - 1) {
            resolve()
          }
        })
      })

      uploadedFiles.then(() => {
        // after all files are uploaded, set the files state
        setFiles([...(files || []), ...(newFiles || [])])
        setFilesUploaded(true)
      })
    },
    [files, token]
  )

  const { getRootProps, getInputProps, acceptedFiles, isDragActive, open } =
    useDropzone({ onDrop, noClick: true })

  useEffect(() => {
    if (value && !preloaded) {
      if (!value.length) {
        setFiles(prev => [...prev, value?.node])
        setMedia(prev => new Set(prev).add(value?.node?.databaseId))
      }
      if (files?.length > 0) {
        setFiles(value)
        // add media id to media array
        if (value.length > 0) {
          value.forEach((file: any) => {
            setMedia(prev => new Set(prev).add(file.databaseId))
          })
        }
      }
    }
  }, [value, preloaded])

  useEffect(() => {
    // get any documents that are already uploaded for suppliers
    if (profileContext?.data?.supplierDetails && !preloaded) {
      const documents = profileContext?.data?.supplierDetails?.documents
        ?.filter(item => item !== null)
        .filter(item => item !== undefined)

      // if documents are already uploaded, add them to the files array
      if (name === "documents" && documents?.length > 0) {
        setFiles(documents)
        documents.forEach((doc: any) => {
          if (!doc.file) return
          setMedia(prev => new Set(prev).add(doc.file.databaseId))

          const title = JSON.stringify({
            id: doc.file.databaseId,
            title: doc.title,
          })
          setMediaTitles(prev => new Set(prev).add(title))
        })
      }
      if (name !== "documents" && profileContext?.data?.supplierDetails[name]) {
        setFiles([profileContext?.data?.supplierDetails[name]])
        setMedia(prev =>
          new Set(prev).add(
            profileContext?.data?.supplierDetails[name].databaseId
          )
        )
      }
      setPreloaded(true)
    }
  }, [profileContext, preloaded])

  useEffect(() => {
    // get any documents that are already uploaded for suppliers news
    if (postContext?.data?.newsPost && !preloaded) {
      const post = postContext?.data?.newsPost

      if (post?.documents && name === "documents") {
        // clean up the documents array
        const documents = post?.documents
          .map((document: any) => document.file)
          .filter((item: any) => item !== null)

        setFiles(post?.documents.filter((item: any) => item?.file !== null))
        setMedia(prev =>
          new Set(prev).add(documents.map((file: any) => file?.databaseId))
        )

        // set the media titles
        const mediaItems = post?.documents
          .map((document: any) => {
            if (!document?.file || !document?.title) return

            return JSON.stringify({
              id: document.file.databaseId,
              title: document.title,
            })
          })
          .filter((item: any) => item !== undefined)

        mediaItems.forEach((item: any) => {
          setMediaTitles(prev => new Set(prev).add(item))
        })

        setPreloaded(true)
      }
    }
  }, [postContext, preloaded, mediaTitles])

  const handleDelete = (fileToDelete: any) => {
    // remove the file from the files array
    const filesWithoutDeleted = files.filter(
      item =>
        (item?.file?.databaseId || item?.databaseId) !== fileToDelete.databaseId
    )
    setFiles(removeDuplicates(filesWithoutDeleted))

    // convert media to array
    const mediaArray = removeDuplicates(setToArray(media))
    if (mediaArray && mediaArray.length > 0) {
      mediaArray?.forEach((item: any, index: number) => {
        if (item === fileToDelete.databaseId) {
          mediaArray.splice(index, 1)
        }
      })
    }
    setMedia(new Set(removeDuplicates(mediaArray)))

    // remove the media title
    const mediaTitlesArray = Array.from(mediaTitles)

    mediaTitlesArray.forEach(item => {
      const parsedItem = JSON.parse(item)
      if (parsedItem.id === fileToDelete.databaseId) {
        // remove the item from the mediaTitlesArray
        mediaTitlesArray.splice(mediaTitlesArray.indexOf(item), 1)
      }
    })

    setMediaTitles(new Set(mediaTitlesArray))

    if (postContext?.data?.id) {
      if (name === "documents") {
        // console.log({
        //   id: postContext?.data?.id || profileContext?.data?.id,
        //   approvalStatus: false,
        //   documents: removeDuplicates(mediaArray)?.toString() || "",
        //   documentsTitles: removeDuplicates(mediaTitlesArray)?.toString() || "",
        // })
        updateSupplierNewsItemMutation({
          variables: {
            id: postContext?.data?.id || profileContext?.data?.id,
            approvalStatus: false,
            documents: removeDuplicates(mediaArray)?.toString() || "",
            documentsTitles:
              removeDuplicates(mediaTitlesArray)?.toString() || "",
          },
          refetchQueries: [
            {
              query: GET_SUPPLIER_NEWS_ITEM,
              variables: { slug: postContext?.data?.slug },
            },
          ],
          onCompleted(data) {
            toast.success("File deleted", {
              transition: Slide,
              autoClose: 3000,
              hideProgressBar: true,
              onClose: () => {},
            })
          },
        })
      }
      if (name === "postImage") {
        updateSupplierNewsItemMutation({
          variables: {
            id: postContext?.data?.id || profileContext?.data?.id,
            approvalStatus: false,
            image: "",
          },
          refetchQueries: [
            {
              query: GET_SUPPLIER_NEWS_ITEM,
              variables: { slug: postContext?.data?.slug },
            },
          ],
          onCompleted(data) {
            toast.success("File deleted", {
              transition: Slide,
              autoClose: 3000,
              hideProgressBar: true,
              onClose: () => {},
            })
          },
        })
      }
    }
    if (profileContext?.data?.id) {
      if (name === "documents") {
        updateSupplier({
          variables: {
            id: profileContext?.data?.id,
            approvalStatus: false,
            documents: removeDuplicates(mediaArray)?.toString() || "",
            documentsTitles:
              removeDuplicates(mediaTitlesArray)?.toString() || "",
          },
          refetchQueries: [
            {
              query: GET_SUPPLIER,
              variables: { slug: profileContext?.data?.slug },
            },
          ],
          onCompleted() {
            toast.success("File deleted", {
              transition: Slide,
              autoClose: 3000,
              hideProgressBar: true,
              onClose: () => {},
            })
          },
        })
      }
      if (name === "heroBanner") {
        updateSupplier({
          variables: {
            id: profileContext?.data?.id,
            approvalStatus: false,
            heroBanner: "",
          },
          refetchQueries: [
            {
              query: GET_SUPPLIER,
              variables: { slug: profileContext?.data?.slug },
            },
          ],
          onCompleted() {
            toast.success("File deleted", {
              transition: Slide,
              autoClose: 3000,
              hideProgressBar: true,
              onClose: () => {},
            })
          },
        })
      }
      if (name === "profilePicture") {
        updateSupplier({
          variables: {
            id: profileContext?.data?.id,
            approvalStatus: false,
            profilePicture: "",
          },
          refetchQueries: [
            {
              query: GET_SUPPLIER,
              variables: { slug: profileContext?.data?.slug },
            },
          ],
          onCompleted() {
            toast.success("File deleted", {
              transition: Slide,
              autoClose: 3000,
              hideProgressBar: true,
              onClose: () => {},
            })
          },
        })
      }
    }
  }

  // monitor the formSaved state and update the filesUploaded state
  useEffect(() => {
    if (formSaved) {
      setFilesUploaded(false)
    }
  }, [formSaved])

  // monitor files length and update the filesUploaded state
  useEffect(() => {
    if (files === undefined || files.length === 0) {
      setFilesUploaded(false)
    }
    if (files && files.length >= limit) {
      setFilesUploaded(true)
    }
  }, [files])

  return (
    <div className="relative @container">
      {/* add hidden input to save mediaId */}
      <input
        type="hidden"
        name={name}
        value={`${Array.from(media)}`
          .split(",")
          .filter((id: string, index: number, array: string[]) => {
            return array.indexOf(id) === index
          })
          .toString()}
      />
      {name === "documents" ? (
        <input
          type="hidden"
          name={`${name}Titles`}
          value={`${Array.from(mediaTitles)}`}
        />
      ) : null}
      <div className="vs-shadow mb-12 rounded-3xl bg-theme-white p-4 @sm:p-7">
        {/* TODO: pass example as a prop */}
        {example && (
          <div className="relative aspect-square">
            {example}
            <p>Example</p>
          </div>
        )}
        {!filesUploaded && (files?.length < limit || !files) ? (
          <div
            className={`group grid aspect-[5/2] flex-1 place-items-center rounded-md border-2 border-dashed border-orange-500/70 transition hover:border-orange-500/100 ${
              files?.length >= limit
                ? "pointer-events-none opacity-50"
                : "cursor-pointer"
            }`}
            {...getRootProps({
              onClick: open,
            })}
          >
            <input {...getInputProps()} />
            <p className="px-2 text-center">
              {isDragActive
                ? "Drop the files here ..."
                : "Drag 'n' drop some files here, or click to select files"}
            </p>
          </div>
        ) : filesUploaded && files?.length < limit && !formSaved ? (
          <div className="rounded-md bg-theme-orange-light/20 p-4 text-center text-caption font-medium text-theme-orange">
            Save edits to upload more files
          </div>
        ) : null}

        {uploading ? (
          <div className="my-6 flex items-center justify-center gap-4 opacity-60">
            Uploading... <Loader loading={uploading} size={20} />
          </div>
        ) : (
          <FileList
            name={name}
            files={files}
            onDelete={handleDelete}
            media={media}
            setMedia={setMedia}
            mediaTitles={mediaTitles}
            setMediaTitles={setMediaTitles}
          />
        )}
      </div>
    </div>
  )
}

export default Dropzone

interface SingleFile extends File {
  path?: string
  fileName?: string
  fileSize?: number
  title?: string
  databaseId?: number
  file?: any
}

interface FileListProps {
  files: SingleFile[]
  onDelete: (file: any) => void
  name: string
  media: Set<any[]>
  setMedia: any
  mediaTitles: Set<any[]>
  setMediaTitles: any
}

const FileList = ({
  name,
  files,
  onDelete,
  media,
  setMedia,
  mediaTitles,
  setMediaTitles,
}: FileListProps) => {
  // check if files is an array
  const isFilesArray = Array.isArray(files)

  const filesArr = isFilesArray ? files : files ? [files?.node] : files

  if (!filesArr || filesArr.length === 0) return null

  return (
    <ul>
      {filesArr?.map((file: SingleFile, index: number) => (
        <File
          key={`media-${index}`}
          file={file}
          onDelete={onDelete}
          name={name}
          media={media}
          setMedia={setMedia}
          mediaTitles={mediaTitles}
          setMediaTitles={setMediaTitles}
        />
      ))}
    </ul>
  )
}

interface FileProps {
  file: SingleFile
  onDelete: (file: any) => void
  name: string
  media?: Set<any[]>
  setMedia?: any
  mediaTitles?: Set<any[]>
  setMediaTitles?: any
}

const File = ({
  name,
  file,
  onDelete,
  media,
  setMedia,
  mediaTitles,
  setMediaTitles,
}: FileProps) => {
  if (!file) return null

  const fileForUse: SingleFile = file?.file ? file?.file : file

  const [titleState, setTitleState] = useState<"unset" | "dirty" | "saved">(
    "unset"
  )
  const [title, setTitle] = useState<string>(file?.title || "")

  function handleInputChange(e: any) {
    setTitleState("dirty")
    setTitle(e.target.value)
  }

  // parse the mediaTitles array
  const currentMediaTitles = Array.from(mediaTitles).map((item: any) => {
    return JSON.parse(item)
  })

  function handleTitleSave() {
    setTitleState("saved")
    const newMediaTitle = JSON.stringify({
      id: fileForUse?.databaseId,
      title: title,
    })

    // check mediaTitles for existing title with same id
    const existingTitle = currentMediaTitles.find((item: any) => {
      if (!item) return
      return item.id === fileForUse?.databaseId
    })

    // if existing title, remove it
    if (existingTitle) {
      // stringify the existing title
      const existing = JSON.stringify(existingTitle)
      // remove the existing title from the mediaTitles array
      mediaTitles?.delete(existing)
      // add the new title to the mediaTitles array
      setMediaTitles(new Set(mediaTitles).add(newMediaTitle))
      return
    }
    // if no existing title, add the new title to the mediaTitles array
    setMediaTitles(new Set(mediaTitles).add(newMediaTitle))
  }

  return (
    <li
      className={`p-4 ${
        name === "documents" ? "mt-4 rounded-lg bg-theme-yellow p-4" : ""
      }`}
    >
      {name === "documents" ? (
        <div className="flex flex-col gap-4">
          <h3>Document title</h3>
          <div className="relative">
            <TextInput
              name={`documentTitle-${fileForUse?.databaseId}`}
              type={"text"}
              value={file?.title ? file?.title : title}
              className="!shadow-none"
              onChange={handleInputChange}
            />
            {titleState === "dirty" ? (
              <button
                className="absolute right-2 top-2 h-11 w-11 rounded-full bg-theme-orange p-3 text-theme-white"
                title="Save title"
                onClick={handleTitleSave}
              >
                <FaCheck />
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
      <div className={`flex items-center`}>
        <div className={`icon relative w-6 md:w-10`}>
          <img src={fileIcon} className={`w-6 md:w-10`} />
          <span
            className={`absolute bottom-2 left-1 right-1 text-center text-[12px] font-bold uppercase tracking-wider text-theme-orange`}
          >
            {fileForUse?.path
              ? fileForUse?.path.substr(fileForUse?.path.lastIndexOf(".") + 1)
              : ``}
          </span>
        </div>
        <div className={`flex-1 px-8`}>
          <p className={`text-small font-bold`}>
            {fileForUse?.path ? fileForUse.path : fileForUse?.fileName}
          </p>
          <p className={`text-caption text-theme-gray-mid`}>
            {bytesToSize(
              fileForUse?.size ? fileForUse.size : fileForUse?.fileSize || 0
            )}
          </p>
        </div>
        <button
          type="button"
          className={`rounded-full bg-theme-orange p-4 transition hover:bg-theme-orange-light [&_svg]:h-5 [&_svg]:w-5 [&_svg]:md:h-6 [&_svg]:md:w-6`}
          onClick={() => onDelete(fileForUse)}
        >
          <FaTrash />
        </button>
      </div>
    </li>
  )
}
