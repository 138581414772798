import { useGetSupplierQuery } from "~/graphql/generated/graphql"

const useSupplier = (slug: string) => {
  const { data, loading, error } = useGetSupplierQuery({
    variables: { slug: slug },
  })

  return { data: data?.supplier, loading, error }
}

export default useSupplier
