import React, { useEffect, useMemo, useRef, useState } from "react"
import { FaSearch } from "react-icons/fa"
import { globalHistory } from "@reach/router"
import { excerptify } from "~/utils/helpers"
import useSupplierCategories from "~/hooks/useSupplierCategories"
import useSupplierCategory from "~/hooks/useSupplierCategory"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import SlideArrow from "~/components/reusable/svgs/SlideArrow"
import Link from "~/components/Link"
import useImages from "~/hooks/useImages"
import PreviewImage from "../reusable/PreviewImage"
import useSuppliersStatic from "~/hooks/useSuppliersStatic"
import Pagination from "../Pagination"
import usePagination from "~/hooks/usePagination"
import CategoryTiles from "../CategoryTiles"
import useUser from "~/hooks/useUser"
import LinkedAccountManager from "../LinkedAccountManager"

function keywords(nodes: { id: string; name: string; slug: string }[]) {
  return nodes?.map(node => node?.name).join(" ")
}
type Props = {
  slug: string
  path: string
}
export default function SingleSupplierCategory({ slug, path }: Props) {
  const { data, loading, error } = useSupplierCategory(slug)
  const [filterName, setFilterName] = useState("")
  const [filterCategory, setFilterCategory] = useState("")
  const { data: categories } = useSupplierCategories(slug)
  const suppliers = useSuppliersStatic(slug)
  const { user } = useUser()

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setFilterName("")
        setFilterCategory("")
      }
    })
  }, [])

  // filter suppliers by category
  const filteredSuppliersByCategory = useMemo(() => {
    if (filterCategory === "") {
      return suppliers
    }
    return suppliers?.filter(supplier => {
      return (
        filterCategory !== "" &&
        supplier?.supplierCategories?.nodes?.some(
          category => category?.slug === filterCategory
        )
      )
    })
  }, [filterCategory, suppliers])

  // filter suppliers by name
  const filteredSuppliers = filteredSuppliersByCategory?.filter(supplier => {
    return (
      supplier?.title?.toLowerCase()?.includes(filterName.toLowerCase()) ||
      supplier?.supplierKeywords?.nodes?.some(
        keyword => keyword?.slug === filterName.toLowerCase()
      )
    )
  })

  // sort filtered suppliers by name alphabetically
  const sortedSuppliers = filteredSuppliers?.sort((a, b) => {
    if (a?.title.toLowerCase() < b?.title.toLowerCase()) {
      return -1
    }
    if (a?.title.toLowerCase() > b?.title.toLowerCase()) {
      return 1
    }
    return 0
  })

  // put featured suppliers at the top of the list
  const featuredSuppliers = sortedSuppliers?.filter(
    supplier => supplier?.supplierDetails?.featured === true
  )
  const nonFeaturedSuppliers = sortedSuppliers?.filter(
    supplier => supplier?.supplierDetails?.featured !== true
  )

  // merge featured and non featured suppliers
  const mergedSuppliers = [...featuredSuppliers, ...nonFeaturedSuppliers]

  const [activePage, activeItems, paginationPages, setActivePage] =
    usePagination({
      items: mergedSuppliers,
      show: 18,
      filtered: Boolean(filterName),
    })
  const suppliersList = useRef(null)
  return (
    <>
      <header className={`bg-theme-yellow pt-10 md:pt-20`}>
        <Wrapper>
          <h1
            className={`flex-set-0-0 mx-auto basis-full px-3 text-h2 font-bold md:text-h1 lg:basis-5/6`}
          >
            {loading ? <span className="blur-lg">Benefits</span> : data?.name}
          </h1>
        </Wrapper>
        <Spacer top={`yellow`} bottom={`white`} />
      </header>
      <Wrapper>
        {/* filter suppliers by name and by category */}
        <div className="w-full flex-none">
          <div
            className={`vs-shadow mx-3 mb-12 -mt-8 grid grid-cols-1 items-center rounded-[32px] bg-theme-yellow p-6 sm:p-8 md:grid-cols-12 md:py-11 md:px-12`}
          >
            <h2 className={`text-h4 font-bold md:text-h3`}>Filter</h2>
            <div className={`relative m-3 md:col-span-5 md:col-start-3`}>
              <label htmlFor="" className={`mb-2 block text-caption`}>
                Search by name
              </label>
              <input
                type="text"
                name="name"
                value={filterName}
                onChange={e => setFilterName(e.target.value)}
                className={`input rounded-4 vs-shadow-light border-none p-5 text-caption text-theme-gray-mid`}
              />
              <i
                className={`absolute right-4 top-8 z-[2] h-[38px] w-[38px] rotate-90 overflow-hidden rounded-full bg-theme-orange p-3 text-theme-white`}
              >
                <FaSearch />
              </i>
            </div>

            {categories.length > 0 && (
              <div className={`relative m-3 md:col-span-5`}>
                <label htmlFor="" className={`mb-2 block text-caption`}>
                  Category
                </label>
                <select
                  name="category"
                  onChange={e => setFilterCategory(e.target.value)}
                  className={`input text-gray-mid rounded-4 vs-shadow-light z-[1] appearance-none border-none px-5 py-4 text-normal font-bold`}
                >
                  <option value="">All</option>
                  {categories.map(category => (
                    <option key={category?.id} value={category?.slug || ""}>
                      {category?.name}
                    </option>
                  ))}
                </select>
                <i
                  className={`pointer-events-none absolute right-4 top-8 z-[2] h-[38px] w-[38px] rotate-90`}
                >
                  <SlideArrow />
                </i>
              </div>
            )}
          </div>
        </div>

        <div
          ref={suppliersList}
          className="mx-3 mb-10 grid basis-almost-full scroll-mt-4 grid-cols-1 gap-6 md:grid-cols-2 md:gap-8 lg:grid-cols-3"
        >
          {activeItems?.map(supplier => (
            <SupplierCard
              key={supplier?.id}
              supplier={supplier}
              pageSlug={slug}
            />
          ))}
        </div>

        <Pagination
          scrollToTarget={suppliersList}
          activePage={activePage}
          setActivePage={setActivePage}
          paginationPages={paginationPages}
        />
      </Wrapper>
      <Spacer top={`white`} bottom={`orange-lighter`} />
      <div className={`bg-theme-orange-lighter pb-10 pt-10`}>
        <Wrapper>
          <CategoryTiles filter={slug} />
        </Wrapper>
      </div>
      {user?.userGroup !== "suppliers" ? (
        <>
          <LinkedAccountManager
            postcode={user?.linkedGroupData?.memberDetails?.postcode || ""}
          />
          <div className={`relative z-[2]`}>
            <Spacer top="transparent" bottom="black" />
          </div>
        </>
      ) : (
        <div className={`relative z-[2]`}>
          <Spacer top={`orange-lighter`} bottom={`black`} />
        </div>
      )}
    </>
  )
}

const SupplierCard = ({
  supplier,
  pageSlug,
}: {
  supplier: any
  pageSlug: string
}) => {
  const { title, slug, supplierKeywords, supplierDetails } = supplier

  const banner =
    useImages(supplierDetails?.heroBanner?.fileName || "null") || null
  const logo =
    useImages(supplierDetails?.profilePicture?.fileName || "null") || null

  return (
    <div
      key={supplier?.id}
      data-keywords={keywords(supplierKeywords?.nodes)}
      className={`vs-shadow relative my-3 flex flex-col overflow-hidden rounded-3xl`}
    >
      <div className="relative">
        {banner ? (
          <PreviewImage
            image={banner}
            fallbackUrl={banner?.publicUrl}
            alt={banner?.altText}
            className={"aspect-video"}
          />
        ) : (
          <div className="aspect-video bg-zinc-300"></div>
        )}
        <div className="logo absolute right-11 bottom-[-71px] z-[2] flex h-[142px] w-[142px] items-center justify-center overflow-hidden rounded-full border-8 border-theme-orange bg-theme-white p-1">
          {logo && (
            <PreviewImage
              image={logo}
              fallbackUrl={logo?.publicUrl}
              alt={logo?.altText}
              className={""}
            />
          )}
        </div>
      </div>
      <div className="con-wrap relative flex h-full flex-col p-6 pt-16 md:p-10 md:pt-20 [&_p]:mb-6 [&_p]:[&_p]:text-small [&_p]:md:mb-12">
        <div className="line absolute bottom-full left-0 block h-[6px] w-4/5 bg-theme-orange "></div>
        {supplierDetails?.featured ? (
          <div className="absolute top-0 left-0 w-2/3 bg-theme-orange px-5 pb-3 pt-2 font-bold uppercase">
            Featured
          </div>
        ) : null}
        <h4 className="mb-6 text-h5 md:text-h5">{title}</h4>
        {supplierDetails?.memberBenefits && (
          <div className="mb-4">
            {excerptify(supplierDetails?.memberBenefits)}
          </div>
        )}
        <Link
          to={`/portal/members/benefits/${pageSlug}/${slug}`}
          className="mt-auto flex items-center"
          activeClassName={undefined}
          forceBlank={undefined}
        >
          <i className="mr-3 h-[38px] w-[38px]">
            <SlideArrow />
          </i>
          <span className="block font-bold text-theme-orange">View Offer</span>
        </Link>
      </div>
    </div>
  )
}
