import React from "react"
import useImages from "~/hooks/useImages"
import useSupplierCategories from "~/hooks/useSupplierCategories"
import Link from "./Link"
import PreviewImage from "./reusable/PreviewImage"
import SlideArrow from "./reusable/svgs/SlideArrow"

const CategoryTiles = ({ filter }: { filter?: string }) => {
  const { data: categories } = useSupplierCategories()

  // sort categories by name reverse alphabetically
  const sortedCategories = categories?.sort((a, b) => {
    return a?.slug?.localeCompare(b?.slug)
  })

  // filter out categories that match filter
  const filteredCategories = sortedCategories?.filter(
    category => category?.slug !== filter
  )

  return (
    <div className="">
      <div className={`flex gap-6 justify-center`}>
        {filteredCategories.map((cat: any, index: number) => {
          const banner = useImages(cat?.supCatImage?.image?.fileName || "")

          return (
            <Link
              key={cat.id}
              to={`/portal/members/benefits/${cat.slug}`}
              activeClassName={undefined}
              forceBlank={undefined}
              className={`vs-shadow overflow-hidden rounded-[32px] bg-theme-white w-full md:w-1/3 lg:w-1/4 relative`}
            >
              {banner && (
                <PreviewImage
                  alt={""}
                  className="post-img aspect-video"
                  fallbackUrl={banner.publicUrl}
                  image={banner}
                />
              )}
              <div
                className={`wrap border-t-[6px] border-t-theme-orange p-6 pb-16 xl:px-10 xl:py-8 xl:pb-20`}
              >
                <h5
                  className={`mb-5 text-h6 font-black tracking-[-0.024em] md:text-h5`}
                >
                  {cat.name}
                </h5>
                <p className={`mb-4 text-small text-theme-gray-mid`}>
                  {cat.description}
                </p>
                <i className={`block h-[38px] w-[38px] absolute bottom-6 md:bottom-8`}>
                  <SlideArrow />
                </i>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default CategoryTiles
