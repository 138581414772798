import { navigate } from "gatsby"
import React from "react"
import {
  useSendEmailMutation,
  useUpdateSupplierNewsItemMutation,
} from "~/graphql/generated/graphql"
import useEmailDetails from "~/hooks/useEmailDetails"
import useUser from "~/hooks/useUser"
import { backendUrl } from "~/lib/constants"
import { GET_SUPPLIER_NEWS_ITEM } from "~/lib/queries"
import { changeDynamicBody } from "~/utils/helpers"
import Button from "./reusable/Button"

type PublishRequestProps = {
  updating: any
  post: any
  buttonStyle?: boolean
}

const PublishRequest = ({
  updating,
  post,
  buttonStyle,
}: PublishRequestProps) => {
  const { user } = useUser()
  const details = useEmailDetails("postPublishReview")
  const [sendEmail, { data, loading, error }] = useSendEmailMutation()
  const [
    updateSupplierNewsItemMutation,
    { data: updatedData, loading: updatingPost, error: updateError },
  ] = useUpdateSupplierNewsItemMutation()

  if (!details) return null

  const emailBody = changeDynamicBody(
    details.body,
    ["{{business_name}}", "{{business_email}}", "{{article_cms_link}}"],
    [
      user?.linkedGroupData?.title || "",
      user?.linkedGroupData?.supplierDetails?.emailAddress || "",
      `${backendUrl}/wp-admin/post.php?post=${post?.databaseId}&action=edit`,
    ]
  )

  const handleRequest = () => {
    // Send email to Vetshare
    sendEmail({
      variables: {
        to: details?.to || "",
        from: details?.from || "",
        subject: details?.subject || "",
        body: emailBody || "",
      },
      onCompleted(data) {
        // if (data?.sendEmail?.sent) {
        //   console.log("Email sent!")
        //   navigate(`/portal/suppliers/posts/review`, {
        //     state: {
        //       emailSent: true,
        //     },
        //   })
        // }
        updateSupplierNewsItemMutation({
          variables: {
            id: post?.id,
            inReview: true,
          },
          refetchQueries: [
            {
              query: GET_SUPPLIER_NEWS_ITEM,
              variables: { slug: post?.slug },
            },
          ],
        })
      },
    })
  }

  const inReview = post?.newsPost?.inReview
  const approved = post?.newsPost?.approvalStatus
  const buttonLabel = inReview && !approved ? "In Review" : "Submit for Review"

  if (approved === true) return null

  if (buttonStyle) {
    return (
      <Button
        type="button"
        disabled={updating || inReview}
        text={
          loading ? "Sending..." : data?.sendEmail?.sent ? "Sent!" : buttonLabel
        }
        buttonStyle="primary"
        buttonColor="light-orange"
        className="!mb-0"
        onClick={handleRequest}
      />
    )
  }

  return (
    <button
      type="button"
      disabled={updating || inReview}
      className={`${inReview ? "cursor-not-allowed" : "underline"}  block
        w-full bg-theme-orange py-10 px-5 text-center
      font-bold`}
      onClick={handleRequest}
    >
      {loading ? "Sending..." : data?.sendEmail?.sent ? "Sent!" : buttonLabel}
    </button>
  )
}

export default PublishRequest
