import React from "react"
import { navigate } from "gatsby"
import useAuth from "~/hooks/useAuth"
import { UserValidationSkeleton } from "../reusable/Skeleton"

const PrivateRoute = ({ component: Component, location, ...rest }: any) => {
  const { loggedIn, user, loading } = useAuth()

  if (loading) return <UserValidationSkeleton />

  if (!loggedIn && location.pathname !== `/portal/login` && !loading) {
    navigate("/portal/login")
    return null
  }

  const userGroup = user?.userGroup
  const userHasPermission = Boolean(
    userGroup ? location.pathname.includes(userGroup) : false
  )

  if (!userHasPermission && loggedIn) {
    navigate("/")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
