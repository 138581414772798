import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { SupplierCategory } from "~/graphql/generated/graphql"

const useSupplierCategories = (filter?: string) => {
  const data = useStaticQuery(graphql`
    query getSupplierCategories {
      allWpSupplierCategory(sort: { order: DESC, fields: ancestors___nodes }) {
        nodes {
          id
          name
          slug
          parentId
          description
          supCatImage {
            image {
              fileName
              altText
            }
          }
        }
      }
    }
  `)

  const parentCategories: SupplierCategory[] = useMemo(() => {
    return data?.allWpSupplierCategory?.nodes.filter(
      (cat: SupplierCategory) => cat.parentId === null
    )
  }, [data])

  // get id of filter
  const filterId = useMemo(() => {
    return parentCategories.find((cat: SupplierCategory) => cat.slug === filter)
      ?.id
  }, [filter])

  // get children of filter
  const filteredCategories: SupplierCategory[] = useMemo(() => {
    return data?.allWpSupplierCategory?.nodes.filter(
      (cat: SupplierCategory) => cat.parentId === filterId
    )
  }, [filterId])

  return {
    data: filter ? filteredCategories : parentCategories,
  }
}

export default useSupplierCategories
