import React from "react"
import Page from "../reusable/Page"

const Benefits = () => {
  return (
    <Page title={`Member benefits`}>
      <h1 className="text-2xl font-bold">Your Benefits</h1>
      <div className="">{/* blocks here */}</div>
    </Page>
  )
}

export default Benefits
