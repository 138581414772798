import React, { useEffect, useState } from "react"
import SlideArrow from "~/components/reusable/svgs/SlideArrow"
import { usePostFormStore } from "~/lib/stores/forms"

type DropdownProps = {
  name: string
  placeholder: string
  options: [
    {
      value: string
      label: string
    }
  ]
  selected?: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Dropdown = ({
  name,
  placeholder,
  options,
  selected,
  onChange,
}: DropdownProps) => {
  const [originalValue, setOriginalValue] = useState(selected)

  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  useEffect(() => {
    if (!originalValue) return
    setOriginalValue(selected)
  }, [selected])

  // on change, update the stored fields
  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(e)
    }
    const { value } = e.target

    // if the field is dirty, add it to the dirtyFields array
    if (value !== "" && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if value is same as original value, remove it from the dirtyFields array
    if (value === originalValue) {
      removeDirtyField(name)
    }
  }
  return (
    <>
      <select
        id={name}
        name={name}
        className={`input text-gray-mid rounded-4 vs-shadow-light z-[1] mb-7 appearance-none border-none bg-theme-white px-5 py-4 pr-16 text-normal font-bold`}
        onChange={onChangeHandler}
      >
        <option value="">{placeholder}</option>
        {options &&
          options.map(option => (
            <option
              key={option.value}
              value={option.value}
              selected={selected ? selected === option.value : false}
            >
              {option.label}
            </option>
          ))}
      </select>
      <i className={`absolute right-4 top-8 z-[2] h-[38px] w-[38px] rotate-90`}>
        <SlideArrow />
      </i>
    </>
  )
}

export default Dropdown
