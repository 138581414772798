import { graphql, useStaticQuery } from "gatsby"
import { ClientSettings_Clientsettings_EmailSettings } from "~/graphql/generated/graphql"

const useEmailDetails = (option: string) => {
  const data = useStaticQuery(graphql`
    query EmailDetails {
      wp {
        clientSettings {
          clientSettings {
            emailSettings {
              requestMemberBenefitsEdit {
                to
                from
                subject
                body
              }
              postPublishReview {
                to
                from
                subject
                body
              }
            }
          }
        }
      }
    }
  `)

  const details: ClientSettings_Clientsettings_EmailSettings =
    data?.wp?.clientSettings?.clientSettings?.emailSettings

  return details?.[option]
}

export default useEmailDetails
