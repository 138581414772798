import React from "react"
import { useForm } from "react-hook-form"
import parse from "html-react-parser"
import TagManager from "react-gtm-module"
import useFormInfo from "~/hooks/useFormInfo"
import { isEmail } from "~/utils/helpers"
import { FormFields } from "../formFields/GravityForms"
import Button from "./Button"
import SteppedForm from "./SteppedForm"

interface Props {
  formId: string
  onCompleted?: (data: any) => void
  submitFunction: (data: any) => void
  loading: boolean
  setEmailAddress: (value: string) => void
  setPostcode?: (value: string) => void
  prepopulatedFields?: any[]
  formSubmitted?: boolean
}

const GravityForm = ({
  formId,
  submitFunction,
  loading,
  setEmailAddress,
  setPostcode,
  prepopulatedFields,
  formSubmitted,
}: Props) => {
  const form = useFormInfo(formId)
  const { register, handleSubmit, formState } = useForm()

  // find postcode field
  const postcodeField = form?.formFields?.find(
    (field: any) => field.label && field.label.toLowerCase() === "postcode"
  )

  function onSubmit(data: any) {
    let fields = Object.keys(data).map((item: any, index: number) => {
      if (data[item] !== null) {
        if (data[item].length > 0) {
          if (isEmail(data[item])) {
            setEmailAddress(data[item])
            return {
              id: parseInt(item),
              emailValues: {
                value: data[item],
              },
            }
          }
          if (postcodeField?.id === parseInt(item)) {
            setPostcode && setPostcode(data[item])
          }
          return {
            id: parseInt(item),
            value: data[item]?.toString(),
          }
        }
      }
    })

    // remove null values
    fields = fields.filter(item => item !== undefined)

    TagManager.dataLayer({
      dataLayer: {
        event: "form_submit",
        event_category: "form",
        event_action: "form_submission",
        event_label: `Form ID: ${formId} - ${form?.title}`,
      },
    })

    submitFunction &&
      submitFunction({
        variables: {
          formId: formId,
          fieldValues: fields,
        },
      })
  }

  const sections = form?.formFields?.filter(
    (field: any) => field.type === "SECTION"
  )

  let formFields: any[] = []

  let section = -1

  Array.from(form.formFields).forEach((field: any, index: number) => {
    if (field.type === "SECTION") {
      section = section + 1
    }
    formFields.push({
      ...field,
      section: section,
    })
  })

  const formSections = sections.map((section: any, index: number) => {
    return {
      ...section,
      fields: formFields.filter(
        (field: any) => field.section === index && field.type !== "SECTION"
      ),
    }
  })

  return (
    <div>
      {formSubmitted ? (
        <ThankYouScreen confirmations={form?.confirmations} />
      ) : (
        <form data-formId={formId} onSubmit={handleSubmit(onSubmit)}>
          {formSections.length > 0 && (
            <SteppedForm register={register} fieldSets={formSections} />
          )}

          <div className="mx-auto max-w-[432px]">
            {formSections.length === 0 &&
              form?.formFields?.map((field: any) => {
                if (field.type === "SECTION") return null
                const Field = FormFields[
                  field.type as keyof typeof FormFields
                ] as React.ElementType

                // convert field.label to camelCase
                const fieldName = field.label
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())

                // if field has canPrepopulate set to true, then prepopulate it
                const prepopulatedField = prepopulatedFields?.find(
                  (item: any) => item.id === fieldName
                )

                // add prepopulated value to field
                if (prepopulatedField) {
                  field.value = prepopulatedField.value
                }

                return <Field key={field?.id} {...field} register={register} />
              })}
          </div>

          {formSections.length === 0 && (
            <div className="mx-auto flex max-w-[432px] justify-between [&_.brew-button]:mr-0">
              <div className="font-semibold">
                <span className="text-theme-orange">*</span> Required Fields
              </div>
              <Button
                buttonColor="orange"
                type="submit"
                buttonStyle="primary"
                text={
                  loading
                    ? "Submitting..."
                    : form?.submitButton?.text || "Submit"
                }
              />
            </div>
          )}
        </form>
      )}
    </div>
  )
}

export default GravityForm

const ThankYouScreen = ({ confirmations }: { confirmations: any[] }) => {
  const confirmation = confirmations[0]

  console.log("confirmation", confirmation)

  if (!confirmation && !confirmation.isActive) return null

  return (
    <div className="mx-auto max-w-[432px]">
      <h3 className="text-2xl mb-4 text-center font-bold">
        {confirmation.heading}
      </h3>
      <div className="text-center text-h4 leading-loose">
        {parse(confirmation.message)}
      </div>
    </div>
  )
}
