import React from "react"
import useAccountManager from "~/hooks/useAccountManager"
import { ButtonLink } from "./reusable/Button"
import Wrapper from "./reusable/Wrapper"
import AccountManagerCard from "./AccountManagerCard"
import useTemplateContent from "~/hooks/useTemplateContent"
import { AccountManagerSkeleton } from "./reusable/Skeleton"
import { changeDynamicBody } from "~/utils/helpers"

const LinkedAccountManager = ({ postcode }: { postcode: string }) => {
  const { data, loading, error } = useAccountManager(postcode)
  const { support } = useTemplateContent("memberPortal")

  const text = changeDynamicBody(
    support?.text,
    ["{{account_manager}}"],
    [data?.title]
  )

  return (
    <div
      className={`mb:pt-16 mb-[-90px] bg-theme-orange-lighter pt-8 pb-32 lg:py-32`}
    >
      <Wrapper className={`md:mb-8 md:items-center`}>
        <div
          className={`con-wrap flex-set-0-0 basis-full py-10 md:basis-1/2 lg:mx-1/12 lg:basis-1/3`}
        >
          <h3
            className={`mb-5 px-3 text-h4 font-bold leading-none text-theme-black md:mb-8 md:text-h3`}
          >
            {support.title}
          </h3>
          <p className={`mb-5 px-3 text-normal leading-8 text-theme-black`}>
            {text ? text : support.text}
          </p>
          {support.buttonLabel && (
            <ButtonLink
              text={support.buttonLabel}
              link={`/portal/members/callback`}
              buttonStyle={`primary`}
              buttonColor={`orange`}
            />
          )}
        </div>
        {loading ? (
          <AccountManagerSkeleton />
        ) : (
          <AccountManagerCard accountManager={data} />
        )}
      </Wrapper>
    </div>
  )
}

export default LinkedAccountManager
