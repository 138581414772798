import * as React from "react"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import useAuth from "~/hooks/useAuth"
import SupplierNewsPosts from "~/components/SupplierNewsPosts"
import useSupplierCategories from "~/hooks/useSupplierCategories"
import useAccountManager from "~/hooks/useAccountManager"
import Wrapper from "~/components/reusable/Wrapper"
import PreviewImage from "~/components/reusable/PreviewImage"
import Spacer from "~/components/reusable/svgs/Spacer"
import { ButtonLink } from "~/components/reusable/Button"
import Link from "~/components/Link"
import SlideArrow from "~/components/reusable/svgs/SlideArrow"
import Page from "~/components/reusable/Page"
import dots from "../../images/orange-dots.png"
import useTemplateContent from "~/hooks/useTemplateContent"
import LinkedAccountManager from "../LinkedAccountManager"
import useImages from "~/hooks/useImages"
import CategoryTiles from "../CategoryTiles"
import Seo from "../SEO"

export default function MembersContent() {
  const { user } = useAuth()
  const { data: categories } = useSupplierCategories()
  const meta = useTemplateContent("memberPortalMeta")
  const userPostcode = user?.linkedGroupData?.memberDetails?.postcode

  return (
    <Page>
      <Seo
        title={meta?.title || `Members Portal`}
        description={meta?.description}
      />
      <header className={`relative bg-theme-yellow`}>
        <Wrapper className={`items-center justify-between md:pt-3`}>
          <div
            className={`welcome flex-set-0-0 relative z-[2] mx-auto mt-6 basis-almost-full px-3 md:mx-0 md:mt-14 md:flex-1 lg:mx-1/12`}
          >
            <h1
              className={`block text-[29px] font-bold leading-none tracking-[-0.024em]`}
            >
              Welcome
            </h1>
            <p className={`block text-[29px] leading-none tracking-[-0.024em]`}>
              {user?.linkedGroupData?.title || user?.name}
            </p>
          </div>
        </Wrapper>
        <Spacer top={`yellow`} bottom={`white`} />
      </header>

      <div
        className={`bbl-latest-news-slider-block overflow-hidden bg-theme-white pt-6 pb-8 md:pb-16 md:pt-14 lg:pb-40 [&_.brew-button]:mx-auto`}
      >
        <Wrapper>
          <h3
            className={`flex-set-0-0 mb-5 basis-full px-3 text-center text-h4 font-bold leading-none text-theme-black md:mb-10 md:text-h3`}
          >
            Latest News &amp; Offers
          </h3>
          <SupplierNewsPosts start={0} end={3} show={3} />
          <ButtonLink
            buttonStyle={`primary`}
            buttonColor={`orange`}
            link={`/portal/members/news/`}
            text={`View all news & offers`}
          />
        </Wrapper>
      </div>

      <Spacer top="white" bottom="orange-lighter" />

      {userPostcode && <LinkedAccountManager postcode={userPostcode} />}

      <div className={`relative z-[3]`}>
        <Spacer top="transparent" bottom="white" />
      </div>

      {categories && (
        <div className={`bg-theme-white pb-20 pt-5 md:pb-40`}>
          <Wrapper>
            <CategoryTiles />
          </Wrapper>
        </div>
      )}
      <Spacer top={`white`} bottom={`black`} />
    </Page>
  )
}
