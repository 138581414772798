import React from "react"
import { navigate } from "gatsby"
import { isBrowser } from "../../helpers"
import SlideArrow from "~/components/reusable/svgs/SlideArrow"

type Props = {
  fallback?: string
}

const GoBack = ({ fallback }: Props) => {
  function goBack() {
    if (isBrowser) {
      if (!window.history.state && fallback) {
        const fallbackUrl = fallback
        navigate(fallbackUrl)
      } else {
        navigate(-1)
      }
    }
  }

  return (
    <button className={'text-theme-orange text-normal flex items-center [&_svg]:w-[38px] [&_svg]:h-[38px] [&_svg]:mr-4 [&_svg]:rotate-180 font-bold'} onClick={() => goBack()}>
      <SlideArrow />
      <span>Back</span>
    </button>
  )
}

export default GoBack
