import React from "react"

export default function PostImage({ fallbackUrl, alt, className }) {

  const displayImage = (
    <div className={`gatsby-image-wrapper ${className}`}>
      <img
        src={fallbackUrl}
        alt={alt}
        style={{
          width: "100%",
          position: "relative",
        }}
      />
    </div>
  )

  return displayImage
}