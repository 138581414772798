import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { useLocation, globalHistory, useNavigate } from "@reach/router"
import { usePostFormStore } from "~/lib/stores/forms"

type Props = {
  submitHandler: (event: React.FormEvent<HTMLFormElement>) => void
  className?: string
  children?: React.ReactNode
}

// https://github.com/reach/router/issues/464#issuecomment-953052429
const Form = ({ submitHandler, className, children }: Props): JSX.Element => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const setDirtyFields = usePostFormStore(state => state.setDirtyFields)

  const navigate = useNavigate()
  const currentLocation = useLocation()

  const [hasConfirmed, setHasConfirmed] = useState(false)

  useEffect(() => {
    const isDirty = Boolean(dirtyFields.length > 0)
    setHasUnsavedChanges(isDirty)
    setHasConfirmed(false)
  }, [dirtyFields])

  // prevent internal navigation if unsaved changes
  useEffect(() => {
    if (hasConfirmed) return
    return globalHistory.listen(({ action, location }) => {
      if (
        (action === "PUSH" &&
          !location.pathname.includes(`${currentLocation.pathname}`)) ||
        action === "POP"
      ) {
        if (!hasUnsavedChanges) return

        if (
          window.confirm("You may have unsaved changes. Are you sure?") &&
          !hasConfirmed
        ) {
          setHasConfirmed(!hasConfirmed)
        } else {
          navigate(currentLocation.pathname)
        }
      }
    })
  }, [hasUnsavedChanges])

  // prevent navigation if unsaved changes
  useEffect(() => {
    if (!hasUnsavedChanges) return

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log("before unload")
      event.preventDefault()
      event.returnValue = ""
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [hasUnsavedChanges])

  return (
    <form
      method="post"
      onSubmit={submitHandler}
      className={clsx(`flex-set-0-0 basis-full`, className)}
    >
      {children}
    </form>
  )
}

export default Form
