import React from "react"

export default function Preview() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 24 24" }}
    >
      <path d="M23.8,11.4C23.6,11.2,19.4,5,12,5C4.6,5,0.4,11.2,0.2,11.4c-0.2,0.4-0.2,0.8,0,1.2C0.4,12.8,4.6,19,12,19 c7.4,0,11.6-6.2,11.8-6.5C24.1,12.2,24.1,11.8,23.8,11.4z M12,17c-5,0-8.5-3.5-9.7-5c1.2-1.4,4.6-5,9.7-5c5,0,8.5,3.6,9.7,5 C20.5,13.4,17,17,12,17z" />
      <path d="M15.4,9.4c-0.6-1-1.7-1.6-2.8-1.7C10.2,7.4,8,9,7.7,11.4C7.4,13.8,9,16,11.4,16.3c0.2,0,0.4,0,0.6,0c0.9,0,1.8-0.3,2.6-0.9 C16.5,14.1,16.9,11.4,15.4,9.4z M14.8,12.4c-0.1,0.7-0.5,1.4-1.1,1.9c-0.6,0.4-1.3,0.6-2,0.5c-1.5-0.2-2.6-1.6-2.4-3.1 c0.1-0.7,0.5-1.4,1.1-1.9c0.6-0.4,1.3-0.6,2-0.5C13.9,9.5,15,10.9,14.8,12.4z" />
      <path d="M13.6,11.9C13.6,11.9,13.6,11.9,13.6,11.9c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7 c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.5-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3C13.9,10.8,14,11.5,13.6,11.9z" />
    </svg>
  )
}
