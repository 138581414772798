import React, { useContext, useEffect, useState } from "react"
import { format } from "date-fns"
import { expiryDate } from "~/utils/helpers"
import { usePostFormStore } from "~/lib/stores/forms"

type Props = {
  name: string
  value: string | number | readonly string[] | undefined
  required?: boolean | undefined
  disabled?: boolean | undefined
  loaded?: boolean | undefined
}

const DateField = ({ name, required, value, disabled, loaded }: Props) => {
  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  const [defaultDate, setDefaultDate] = useState<string | undefined>(
    format(new Date(expiryDate), "yyyy-MM-dd")
  )
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    defaultDate
  )

  useEffect(() => {
    if (value) {
      setDefaultDate(format(new Date(value as string), "yyyy-MM-dd"))
      setSelectedDate(format(new Date(value as string), "yyyy-MM-dd"))
    }
  }, [value])

  // on change, update the stored fields
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    // if the field is dirty, add it to the dirtyFields array
    if (value !== defaultDate && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if the field is dirty, add it to the dirtyFields array
    if (value !== "" && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if the field is clean, remove it from the dirtyFields array
    if (value === "" && dirtyFields.includes(name)) {
      removeDirtyField(name)
    }

    // if the field is clean and the original value is not empty, add it to the dirtyFields array
    if (value === "" && defaultDate !== "") {
      addDirtyField(name)
    }

    // if value is same as original value, remove it from the dirtyFields array
    if (value === defaultDate) {
      removeDirtyField(name)
    }

    setSelectedDate(value)
  }

  if (!loaded) return null

  return (
    <input
      id={name}
      className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
      type="date"
      name={name}
      required={required}
      value={selectedDate}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default DateField
