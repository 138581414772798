import React from "react"

export default function Edit() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 24 24" }}
    >
      <path d="M22.1,12.9v7.6c0,1.9-1.6,3.5-3.5,3.5H3.5c-2,0-3.5-1.6-3.5-3.5V5.4c0-2,1.6-3.5,3.5-3.5h7.5c0.6,0,1,0.5,1,1 c0,0.5-0.5,1-1,1H3.5C2.7,3.9,2,4.5,2,5.4v15.1C2,21.3,2.7,22,3.5,22h15.1c0.8,0,1.5-0.7,1.5-1.5v-7.7c0-0.5,0.5-1,1-1 C21.7,11.8,22.1,12.3,22.1,12.9z" />
      <path d="M23.2,0.8c-0.6-0.5-1.3-0.8-2-0.8c-0.7,0-1.5,0.3-2,0.8l-8.8,8.8c-0.1,0.1-0.2,0.3-0.3,0.5l-0.8,3.5c-0.1,0.3,0,0.7,0.3,0.9 c0.2,0.2,0.6,0.3,0.9,0.3l3.5-0.8c0.2,0,0.3-0.1,0.5-0.3l8.8-8.8C24.3,3.8,24.3,2,23.2,0.8z M13.2,12.1l-1.7,0.4l0.4-1.7l6.5-6.5 l1.3,1.4L13.2,12.1z M21.8,3.5l-0.7,0.7l-1.4-1.4l0.7-0.7c0.4-0.4,1-0.4,1.4,0C22.2,2.5,22.2,3.2,21.8,3.5z" />
    </svg>
  )
}
