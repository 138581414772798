import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Router } from "@reach/router"
import useUser from "~/hooks/useUser"
import Layout from "~/components/Layout"
import Loader from "~/components/Loader"
import Page from "~/components/reusable/Page"
import Wrapper from "~/components/reusable/Wrapper"
import PrivateRoute from "~/components/auth/PrivateRoute"
import Members from "~/components/routes/Members"
import MembersNews from "~/components/routes/MembersNews"
import MembersNewsItem from "~/components/routes/{wpSupplierNews.slug}"
import MembersServices from "~/components/routes/MembersServices"
import SupplierCategories from "~/components/routes/{wpSupplierCategories.slug}"
import MembersServicesItem from "~/components/routes/{wpSuppliers.slug}"
import MembersBenefits from "~/components/routes/MembersBenefits"
import Suppliers from "~/components/routes/Suppliers"
import SuppliersEdit from "~/components/routes/SuppliersEdit"
import SuppliersPreview from "~/components/routes/SuppliersPreview"
import SuppliersPosts from "~/components/routes/SuppliersPosts"
import SuppliersPostsEdit from "~/components/routes/{wpSupplierNews.slug}"
import SuppliersPostsNew from "~/components/routes/AddSupplierPost"
import LogIn from "~/components/routes/LogIn"
import ForgotPassword from "~/components/routes/ForgotPassword"
import PublishReview from "~/components/routes/Review"
import Callback from "~/components/routes/Callback"
import CallbackThankYou from "~/components/routes/CallbackThankYou"
import useSessionEnd from "~/hooks/useSessionEnd"

const publicRoutes = ["/portal/forgot-password"]

const Portal = (props: any) => {
  const { loggedIn, user, loading } = useUser()
  const { endSession } = useSessionEnd()

  useEffect(() => {
    // if props.location.search includes refreshStorage=true, then refresh the user data
    if (props.location.search.includes("refreshStorage=true")) {
      endSession()
    }
    // don't redirect if we're loading
    if (loading) return

    // don't redirect if we're on a public route
    if (publicRoutes.includes(props.location.pathname)) return

    // if we're not logged in, redirect to login page
    if (!loggedIn) {
      navigate("/portal/login")
      return
    }

    // if we're logged in, but don't have a user, redirect to login page
    if (loggedIn && !user) {
      navigate("/portal/login")
      return
    }

    if (
      loggedIn &&
      (!props["*"] || props["*"] === "login") &&
      user !== undefined
    ) {
      navigate(`/portal/${user.userGroup}`)
      return
    }
  }, [loggedIn, loading, user])

  return (
    <Layout path={null}>
      <Router basepath="/portal">
        <NotFound default />

        {/* General routes */}
        <LogIn path="/login" />
        <ForgotPassword path="/forgot-password" />

        {/* Member routes */}
        <PrivateRoute component={Members} path="/members" />
        <PrivateRoute component={MembersNews} path="/members/news" />
        <PrivateRoute component={MembersNewsItem} path="/members/news/:slug" />
        <PrivateRoute component={MembersBenefits} path="/members/benefits" />
        <PrivateRoute
          component={SupplierCategories}
          path="/members/benefits/:slug"
        />
        <PrivateRoute
          component={MembersServicesItem}
          path="/members/benefits/:slug/:slug"
        />
        <PrivateRoute component={Callback} path="/members/callback" />
        <PrivateRoute
          component={CallbackThankYou}
          path="/members/callback/thank-you"
        />

        {/* Supplier routes */}
        <PrivateRoute component={Suppliers} path="/suppliers" />
        <PrivateRoute component={SuppliersEdit} path="/suppliers/edit" />
        <PrivateRoute component={SuppliersPreview} path="/suppliers/preview" />
        <PrivateRoute component={SuppliersPosts} path="/suppliers/posts" />
        <PrivateRoute component={Callback} path="/suppliers/callback" />
        <PrivateRoute
          component={SuppliersPostsNew}
          path="/suppliers/posts/new"
        />
        <PrivateRoute
          component={SuppliersPostsEdit}
          path="/suppliers/posts/edit/:slug"
        />
        <PrivateRoute
          component={MembersNewsItem}
          path="/suppliers/posts/preview/:slug"
        />
        <PrivateRoute
          component={PublishReview}
          path="/suppliers/posts/review"
        />
      </Router>
    </Layout>
  )
}

export default Portal

const NotFound = () => {
  // redirect to login if we're not logged in
  useEffect(() => {
    setTimeout(() => {
      navigate("/portal/login")
    }, 1000)
  }, [])

  return (
    <Page title="404">
      <div className=" bg-theme-orange-lighter">
        <Wrapper className="grid min-h-screen !place-items-center">
          <Loader loading={true} />
        </Wrapper>
      </div>
    </Page>
  )
}
