import React from "react"
import useUser from "~/hooks/useUser"
import TextInput from "./TextInput"

const LinkedData = ({ ...fields }) => {
  const { user } = useUser()
  // check if fields.value is an array
  const isArray = Array.isArray(fields.value)

  // if fields.value is an array, get the first item
  let newValue = isArray
    ? fields.value[0]
    : fields.value
    ? fields.value
    : user?.linkedGroupData?.title

  newValue = newValue?.title || newValue?.name || newValue

  fields.value = newValue

  if (!newValue) return null

  return (
    <div>
      <TextInput {...fields} />
    </div>
  )
}

export default LinkedData
