import React, { useRef } from "react"
import useSupplierNews from "~/hooks/useSuppliersNews"
import Link from "~/components/Link"
import PostImage from "~/components/reusable/PostImage"
import SlideArrow from "~/components/reusable/svgs/SlideArrow"
import usePagination from "~/hooks/usePagination"
import Pagination from "./Pagination"
import { CardSkeletons } from "./reusable/Skeleton"
import { excerptify } from "~/utils/helpers"
import { SupplierNews } from "~/lib/types"

const SupplierNewsPosts = ({
  start,
  end,
  show,
}: {
  start?: number
  end?: number
  show?: number
}) => {
  const { data, loading, error } = useSupplierNews(start, end)
  const [activePage, activeItems, paginationPages, setActivePage] =
    usePagination({
      items: data,
      show: show || 6,
      filtered: false,
    })
  const postList = useRef(null)

  if (loading) return <CardSkeletons />

  if (error) return <p>Failed to fetch latest posts</p>

  if (!data.length) return <p>No posts found</p>

  return (
    <div className="relative">
      <div
        ref={postList}
        className="mb-11 grid grid-cols-1 gap-6 px-3 md:grid-cols-2 lg:grid-cols-3"
      >
        {activeItems
          ? activeItems.map((post: any, index: number) => (
              <SinglePost key={index} post={post} />
            ))
          : null}
      </div>
      {show && show > 3 && (
        <Pagination
          scrollToTarget={postList}
          activePage={activePage}
          setActivePage={setActivePage}
          paginationPages={paginationPages}
        />
      )}
    </div>
  )
}

export default SupplierNewsPosts

const SinglePost = ({ post }: { post: SupplierNews }) => {
  const fallbackImage = post?.featuredImage?.node?.mediaItemUrl
  const suppLogo =
    post?.newsPost?.linkedSupplier[0]?.supplierDetails?.profilePicture
      ?.mediaItemUrl

  if (new Date(post.newsPost.expiryDate) < new Date()) {
    return null
  }

  return (
    <div
      className={`post vs-shadow overflow-hidden rounded-[32px] bg-theme-white`}
      key={post.id}
    >
      <div className={`relative`}>
        {fallbackImage !== null ? (
          <PostImage
            alt={post.title}
            className="post-img aspect-[1.777]"
            fallbackUrl={fallbackImage}
          />
        ) : (
          <div className={`post-image-gap block aspect-[1.777]`} />
        )}
        <div className="logo absolute right-11 -bottom-11 z-[2] flex h-[88px] w-[88px] items-center justify-center overflow-hidden rounded-full border-8 border-theme-orange bg-theme-white p-1">
          {suppLogo !== null && (
            <PostImage alt={""} className="post-img" fallbackUrl={suppLogo} />
          )}
        </div>
      </div>
      <div
        className={`con-wrap relative p-6 pt-16 md:p-10 md:pt-20 [&_p]:mb-6 [&_p]:[&_p]:text-small [&_p]:md:mb-12`}
      >
        <div
          className={`line absolute bottom-full left-0 block h-[6px] w-4/5 bg-theme-orange `}
        />
        <h5
          className={`text-theme-block mb-3 text-h6 font-bold tracking-[-0.024em] underline md:text-h5`}
        >
          {post.title}
        </h5>
        <p>{excerptify(post.newsPost.postContent)}</p>
        <Link
          to={`/portal/members/news/${post.slug}`}
          activeClassName={undefined}
          forceBlank={undefined}
          className={`flex items-center`}
        >
          <i className={`mr-3 h-[38px] w-[38px]`}>
            <SlideArrow />
          </i>
          <span className="block font-bold text-theme-orange">Read More</span>
        </Link>
      </div>
    </div>
  )
}
