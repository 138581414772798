import React from "react"
import { youtubeId } from "~/utils/helpers"

const VideoEmbed = ({ url }: { url: string }) => {
  if (!url) return null
  const embedUrl = `https://www.youtube.com/embed/${youtubeId(url)}`
  return (
    <div
      className={`bd-video-block mx-auto mt-10 mb-5 w-[90%] max-w-[794px] md:mt-20 md:mb-10`}
    >
      <div
        className={`res-wrap vs-shadow relative h-0 overflow-hidden rounded-[32px] pb-[56.25%] md:rounded-[64px]`}
      >
        <iframe
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className={`absolute top-0 left-0 h-full w-full`}
        />
      </div>
    </div>
  )
}

export default VideoEmbed
