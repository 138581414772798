import * as React from "react"
import useUser from "~/hooks/useUser"
import useSupplierNews from "~/hooks/useSuppliersNews"
import { Link } from "gatsby"
import { SupplierNews } from "~/lib/types"
import { format, isFuture } from "date-fns"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import Edit from "~/components/reusable/svgs/Edit"
import Preview from "~/components/reusable/svgs/Preview"
import Delete from "~/components/reusable/svgs/Delete"
import { SupplierPostSkeleton } from "../reusable/Skeleton"
import { excerptify, timeAgo } from "~/utils/helpers"
import useImages from "~/hooks/useImages"
import PostImage from "../reusable/PostImage"
import { useDeleteSupplierNewsItemMutation } from "~/graphql/generated/graphql"
import { GET_SUPPLIER_NEWS } from "~/lib/queries"
import { ToastContainer } from "react-toastify"
import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"
import Button from "../reusable/Button"
import Status from "../PostStatus"
import { FaPlus } from "react-icons/fa"

export default function MembersContent() {
  const { user } = useUser()
  const { data, loading, error } = useSupplierNews(
    undefined,
    undefined,
    user?.linkedGroupData?.id
  )

  return (
    <div>
      <header className={`bg-theme-orange-lighter pt-8 md:pt-16`}>
        <Wrapper>
          <h1
            className={`flex-set-0-0 relative z-[2] mx-auto basis-almost-full text-h2 font-bold tracking-[-0.024em] md:text-h1 lg:basis-almost-5/6`}
          >
            News listings
          </h1>
        </Wrapper>
        <Spacer top={`orange-lighter`} bottom={`white`} />
      </header>
      <div className={`pt-5 pb-10 md:pb-20 lg:pb-40`}>
        <Wrapper>
          <div
            className={`post-grid flex-set-0-0 mx-auto basis-almost-full lg:basis-almost-5/6`}
          >
            {!data && loading ? <SupplierPostSkeleton /> : null}
            {data?.map(post => (
              <Post key={post?.id} post={post} />
            ))}
          </div>
          <a
            href={`/portal/suppliers/posts/new`}
            className={`flex-set-0-0 mx-auto flex aspect-[1120/435] basis-almost-full flex-col items-center justify-center rounded-2xl border-2 border-dashed border-theme-orange bg-theme-orange-lighter p-6 md:border-4 lg:basis-almost-5/6`}
          >
            <span className="mb-5 px-3 text-center text-h3 font-bold">
              Add New Post
            </span>
            <span
              className={`icon relative block h-16 w-16 rounded-full bg-theme-orange p-4 text-white md:h-20 md:w-20`}
            >
              <FaPlus />
            </span>
          </a>
        </Wrapper>
      </div>
      <Spacer top={`white`} bottom={`black`} />
    </div>
  )
}

const Post = ({ post }: { post: SupplierNews }) => {
  const { user } = useUser()
  const [deleteItem, { loading: deleting, data }] =
    useDeleteSupplierNewsItemMutation({
      refetchQueries: [
        {
          query: GET_SUPPLIER_NEWS,
        },
      ],
      onCompleted(data) {
        confirmAlert({
          overlayClassName: "confirm-alert-overlay",
          customUI: ({ onClose }) => {
            return (
              <div className="max-w-sm rounded-2xl bg-white px-6 py-8 shadow-xl">
                <div className="flex flex-col items-center px-2">
                  <div className="mb-8 w-20 fill-theme-orange">
                    <Delete />
                  </div>
                  <h2 className="mb-4 text-h3 font-bold">
                    Article <span className="text-theme-orange">Deleted</span>
                  </h2>
                </div>
                <div className="mt-8 flex items-center justify-center gap-8">
                  <Button
                    type="button"
                    buttonColor="orange"
                    onClick={onClose}
                    text={`News Editor`}
                  />
                </div>
              </div>
            )
          },
        })
      },
    })

  const deleted = !deleting && data?.deleteSupplierNews?.deletedId

  const isActive = (post: SupplierNews) =>
    Boolean(
      post?.newsPost?.publishedStatus === true &&
        post?.newsPost?.approvalStatus === true
    )

  const postExpiry = post?.newsPost?.expiryDate
    ? new Date(post?.newsPost?.expiryDate)
    : null

  const featuredImage = useImages(post?.featuredImage?.node?.fileName || "")
  const logo =
    user?.linkedGroupData?.supplierDetails?.profilePicture?.mediaItemUrl

  const deleteSupplierPost = (id: string) => {
    if (!id) return

    confirmAlert({
      overlayClassName: "confirm-alert-overlay",
      customUI: ({ onClose }) => {
        return (
          <div className="max-w-sm rounded-2xl bg-white px-6 py-8 shadow-xl">
            <div className="px-2">
              <h2 className="mb-4 text-h3 font-bold">
                Are you sure you want to delete this news article?
              </h2>
              <p>
                Once it's deleted, the article is permanently removed from our
                servers and cannot be retrieved.
              </p>
            </div>
            <div className="mt-8 flex items-center gap-8">
              <Button
                type="button"
                buttonColor="green"
                onClick={onClose}
                text={`Cancel`}
              />
              <button
                type="button"
                onClick={() => deleteItem({ variables: { id } })}
                className="flex items-center gap-2 py-2 px-4 font-bold text-theme-orange"
              >
                <span className="h-4 w-4 fill-theme-orange">
                  <Delete />
                </span>
                Delete
              </button>
            </div>
          </div>
        )
      },
    })
  }

  return (
    <>
      <ToastContainer />
      <div
        className={`flex-set-0-0 relative mx-auto mb-10 flex basis-full flex-row flex-wrap md:flex-row-reverse md:flex-nowrap md:items-center ${
          deleting || deleted ? "animate-pulse grayscale" : ""
        }`}
      >
        <div
          className={`card-preview vs-shadow flex-set-0-0 z-[2] mx-auto -mb-10 max-w-sm basis-5/6 overflow-hidden rounded-[32px] bg-theme-white md:-ml-96 md:mb-0 lg:mr-10 lg:ml-[-420px] lg:max-w-[420px] lg:basis-almost-5/6 xl:ml-[-460px] xl:mr-16`}
        >
          <div className={`image relative`}>
            {featuredImage ? (
              <PostImage
                fallbackUrl={post?.featuredImage?.node?.mediaItemUrl}
                alt={post?.featuredImage?.node?.altText}
                className={`aspect-video h-full w-full object-cover`}
              />
            ) : (
              <div className="aspect-video bg-zinc-300"></div>
            )}
            <div
              className={`bar absolute block h-2 w-[calc(100%_-_100px)] bg-theme-orange`}
            />
            <div
              className={`logo absolute bottom-[-36px] right-9 flex h-[72px] w-[72px] items-center justify-center rounded-full border-8 border-theme-orange bg-theme-white p-1`}
            >
              {logo ? (
                <PostImage
                  fallbackUrl={logo}
                  alt={undefined}
                  className={undefined}
                />
              ) : null}
            </div>
          </div>
          <div className={`con-wrap px-11 pb-16 pt-14`}>
            <p className={`mb-4 text-normal font-bold tracking-[-0.016em]`}>
              {post.title}
            </p>
            <div className={`text-small`}>
              {excerptify(post?.newsPost?.postContent)}
            </div>
          </div>
        </div>
        <div
          className={`flex-set-0-0 relative z-[1] basis-full rounded-[32px] bg-theme-gray-light/30 p-6 pt-16 md:py-[72px] md:pr-[420px] md:pl-[7%] lg:pr-[530px] xl:pl-[10%]`}
        >
          <h3 className={`mb-5 block text-h4 font-bold md:text-h3`}>
            {post.title}
          </h3>
          <div className="block text-caption font-extrabold uppercase tracking-[0.2em]">
            <Status post={post} />
          </div>

          <p className={`block text-small`}>
            Last Edited: {timeAgo(post.modified)}
          </p>
          {postExpiry && (
            <p className={`block text-small`}>
              {isFuture(postExpiry) ? "Expires: " : "Expired: "}
              {format(postExpiry, "d MMMM yyyy")}
            </p>
          )}
          <div
            className={`actions mt-10 grid max-w-[360px] grid-cols-3 gap-6 @container/actions`}
          >
            <Link
              to={`/portal/suppliers/posts/edit/${post.slug}`}
              className={`flex aspect-square flex-col items-center justify-center space-y-2 rounded-3xl bg-theme-green-light p-4 text-small font-bold tracking-[-0.016em] md:p-6 [&_svg]:block [&_svg]:aspect-square [&_svg]:h-6 [&_svg]:w-6`}
            >
              <Edit />
              <span className="hidden @2xs:inline-block">Edit</span>
            </Link>
            <Link
              to={`/portal/suppliers/posts/preview/${post.slug}`}
              className={`flex aspect-square flex-col items-center justify-center space-y-2 rounded-3xl bg-theme-green-light p-4 text-small font-bold tracking-[-0.016em] md:p-6 [&_svg]:block [&_svg]:h-6 [&_svg]:w-6`}
            >
              <Preview />
              <span className="hidden @2xs:inline-block">Preview</span>
            </Link>
            <button
              type="button"
              onClick={() => deleteSupplierPost(post.id)}
              className={`flex aspect-square flex-col items-center justify-center space-y-2 rounded-3xl bg-theme-orange-light p-4 text-small font-bold tracking-[-0.016em] md:p-6 [&_svg]:block [&_svg]:h-6 [&_svg]:w-6`}
            >
              <Delete />
              <span className="hidden @2xs:inline-block">Delete</span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
