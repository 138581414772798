import { useMemo } from "react"
import { useQuery } from "@apollo/client"
import { GET_SUPPLIER_NEWS } from "~/lib/queries"
import { SupplierNews } from "~/lib/types"

const useSupplierNews = (start?: number, end?: number, supplier?: string) => {
  const { data, loading, error } = useQuery(GET_SUPPLIER_NEWS)

  // clean up data to be more readable
  const news = data?.supplierNewsItems?.edges?.map((edge: any) => edge?.node)

  const filteredNews = useMemo(
    () =>
      news?.filter((item: SupplierNews) => {
        if (supplier) {
          return item.newsPost.linkedSupplier?.find(s => s.id === supplier)
        }
        if (!item.newsPost.approvalStatus || !item.newsPost.publishedStatus) {
          return false
        }
        if (new Date(item.newsPost.expiryDate) < new Date()) {
          return false
        }
        return true
      }),
    [news]
  ) as SupplierNews[]

  // limit the number of items returned
  if (start && end) {
    return {
      data: filteredNews?.slice(start, end),
      loading,
      error,
    }
  }

  return { data: filteredNews, loading, error }
}

export default useSupplierNews
