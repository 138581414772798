import React, { useState } from "react"
import parser from "html-react-parser"
import { toast, Slide } from "react-toastify"
import { useSendEmailMutation } from "~/graphql/generated/graphql"
import useEmailDetails from "~/hooks/useEmailDetails"
import { Field } from "~/lib/types"
import Button from "../reusable/Button"
import { changeDynamicBody } from "~/utils/helpers"
import useUser from "~/hooks/useUser"

type TextEditProps = Field & {
  name: string | undefined
}

const Benefits = ({ name, value }: TextEditProps) => {
  const { user } = useUser()
  const [emailSent, setEmailSent] = useState(false)
  const details = useEmailDetails("requestMemberBenefitsEdit")

  const emailBody = changeDynamicBody(
    details.body,
    ["{{business_name}}", "{{business_email}}"],
    [
      user?.linkedGroupData?.title || "",
      user?.linkedGroupData?.supplierDetails?.emailAddress || "",
    ]
  )

  const [sendEmail, { data, loading, error }] = useSendEmailMutation({
    onCompleted: data => {
      if (data?.sendEmail?.sent) {
        setEmailSent(true)
        toast.success("Edit request sent", {
          transition: Slide,
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {},
        })
      } else {
        toast.error("Error sending request", {
          transition: Slide,
          autoClose: 3000,
          hideProgressBar: true,
          onClose: () => {},
        })
      }
    },
  })

  const handleRequest = () => {
    // Send email to Vetshare
    sendEmail({
      variables: {
        to: details?.to || "",
        from: details?.from || "",
        subject: details?.subject || "",
        body: emailBody || "",
      },
    })

    if (loading) {
      console.log("Email sending...")
    }
    if (error) {
      console.log("Error sending email")
    }
    if (data?.sendEmail?.sent) {
      console.log("Email sent!")
    } else {
      console.log(data)
    }
  }

  return (
    <div id={name} className="@container/benefits">
      {value && (
        <div className="mb-8 cursor-not-allowed opacity-40 [user-select:none]">
          {parser(value)}
        </div>
      )}

      <div
        className={`vs-shadow mb-12 rounded-3xl bg-theme-white p-8 @md:px-10`}
      >
        <p
          className={`mb-4 text-caption font-extrabold uppercase tracking-[.2em] text-theme-orange`}
        >
          Important
        </p>
        <div className={`flex flex-col items-start gap-6 @md:flex-row`}>
          <p className={`flex-1`}>
            These changes are subject to manual review by the VetShare team,
            please request an edit and the team will be in touch.
          </p>
          <Button
            type="button"
            buttonColor="orange"
            disabled={!details || emailSent}
            onClick={handleRequest}
            text={
              loading
                ? "Sending..."
                : data?.sendEmail?.sent
                ? "Sent!"
                : "Request Edit"
            }
            className="max-md:ml-0"
          />
        </div>
      </div>
    </div>
  )
}

export default Benefits
