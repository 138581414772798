export async function createMediaFromFile(
  file: File,
  token: string,
  url: string
): Promise<Response> {
  // Create upload payload
  const data = new FormData()
  data.append("file", file, file.name || file.type.replace("/", "."))

  return fetch(`${url}/wp/v2/media`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  })
}
