import { graphql, useStaticQuery } from "gatsby"

const useFiles = (image?: string) => {
  const data = useStaticQuery(graphql`
    query GET_ALL_FILES {
      allFile(
        filter: { extension: { in: ["pdf", "doc", "docx", "mp3", "mp4"] } }
      ) {
        edges {
          node {
            id
            relativePath
            publicURL
            base
          }
        }
      }
    }
  `)

  // check if image is a string

  if (image && typeof image === "string") {
    const imageNode = data.allFile.edges.find(
      ({ node }: any) => node.base === image
    )

    return imageNode?.node
  }

  if (image === undefined) return data.allFile.edges.map(edge => edge.node)

  return null
}

export default useFiles
