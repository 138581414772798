import * as React from "react"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import Page from "../reusable/Page"
import Button from "../reusable/Button"
import { gql, useMutation } from "@apollo/client"
import { isEmail } from "~/utils/helpers"
import Dot from "../reusable/Dot"

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($username: String!) {
    sendPasswordResetEmail(input: { username: $username }) {
      user {
        databaseId
      }
    }
  }
`

export default function ForgotPassword() {
  const [errorState, setErrorState] = React.useState("")
  const [invalidEmail, setInvalidEmail] = React.useState(false)
  const [wasEmailSent, setWasEmailSent] = React.useState(false)
  const [sendPasswordResetEmail, { loading, error, data }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL
  )

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const { email } = Object.fromEntries(data)

    if (!email && isEmail(email)) {
      setErrorState("Please enter an email address.")
      return
    }
    setErrorState("")
    sendPasswordResetEmail({
      variables: {
        username: email,
      },
    }).then(() => {
      setWasEmailSent(true)
    }).catch(error => {
      console.error(error)
      setInvalidEmail(true)
    })
  }

  return (
    <Page title={`Forgot Password | Vetshare`}>
      <Wrapper className={``}>
        <Dot />
        <div
          className={`login-form flex-set-0-0 z-[2] mx-auto mb-5 max-w-[545px] basis-almost-full rounded-[32px] bg-theme-yellow px-14 pt-10`}
        >
          <h1 className={`mb-6 text-h3 font-bold md:text-h2`}>
            Forgot Password
          </h1>
          {wasEmailSent ? (
            <p className="mb-8">
              Please check your email. A password reset link has been sent to
              you.
            </p>
          ) : (
            <>
              <p className={`mb-4 text-small`}>
                Enter your email and click Get a new password to reset your
                password.
              </p>
              <form onSubmit={handleSubmit} className="mb-8">
                <div className="relative">
                  <label
                    htmlFor="email"
                    className={`mb-2 text-caption uppercase text-black`}
                  >
                    Email
                  </label>

                  <input
                    className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
                    id="email"
                    type="email"
                    name="email"
                    required
                  />
                </div>
                {errorState && (
                  <p className="text-xs italic text-red-500 mb-2">{errorState}</p>
                )}  
                {invalidEmail && (
                  <p className="text-xs italic text-red-500 mb-2">
                    Email not recognised. Try an alternative or contact <a className="underline" href="mailto:admin@vetshare.co.uk">admin@vetshare.co.uk</a> if you have forgotten the email you use to access the VetShare supplier portal
                  </p>
                )}                
                <Button
                  text={loading ? "Loading..." : "Get a new password"}
                  buttonColor="orange"
                  buttonStyle="primary"
                  type="submit"
                />
              </form>
            </>
          )}
        </div>
      </Wrapper>
      <Spacer top={`transparent`} bottom={`black`} />
    </Page>
  )
}
