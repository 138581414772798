import React, { useState } from "react"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import Layout from "~/components/Layout"
import Wrapper from "~/components/reusable/Wrapper"
import PreviewImage from "~/components/reusable/PreviewImage"
import useTemplateContent from "~/hooks/useTemplateContent"
import { SUBMIT_FORM } from "~/components/formFields/GravityForms"
import GravityForm from "~/components/reusable/GravityForm"
import { changeDynamicBody, isBrowser, replaceName } from "~/utils/helpers"
import Spacer from "~/components/reusable/svgs/Spacer"
import useUser from "~/hooks/useUser"
import Seo from "../SEO"
import { ThankYouWrapper } from "../ThankYou"
import useAccountManager from "~/hooks/useAccountManager"
import parse from "html-react-parser"

export default function CallbackThankYou({ path }: { path: string }) {
  if (!isBrowser()) {
    return null
  }

  const { user } = useUser()

  const {
    data: accountManager,
    loading,
    error,
  } = useAccountManager(user?.linkedGroupData?.memberDetails?.postcode || "")

  const content = useTemplateContent("membersCallBackThankYou")
  const meta = useTemplateContent("membersCallbackThankYouMeta")

  const text = changeDynamicBody(
    content?.text,
    ["{{account_manager}}"],
    [accountManager?.title]
  )

  return (
    <>
      <Seo title={meta?.title} description={meta?.description} />
      <div className={`relative mt-[-153px] bg-theme-yellow pt-32`}>
        <div
          className={`dot absolute right-[-160px] top-[-170px] z-[0] h-[550px] w-[550px] rounded-full bg-theme-green md:right-[-320px] md:top-[-340px] md:h-[1058px] md:w-[1058px]`}
        />
        <Wrapper className={`relative z-[2]`}>
          <ThankYouWrapper
            content={content}
            leftContent={
              <div className=" px-3">
                {accountManager && (
                  <h3 className="text-h3">{accountManager?.title}</h3>
                )}
                <h2 className="mb-3 text-h2 font-bold tracking-tight md:text-h1">
                  {content.title}
                </h2>
                <p className={`leading-8 [&_a]:font-bold`}>{parse(text)}</p>
              </div>
            }
            rightContent={
              <>
                {content.steps.map((step: { text: string }, index: number) => {
                  const text = changeDynamicBody(
                    step?.text,
                    ["{{account_manager}}"],
                    [
                      `<span className="font-bold">${accountManager?.title}</span>`,
                    ]
                  )

                  console.log("accountManager", accountManager)

                  return (
                    <div
                      key={index}
                      className={`mt-4 flex items-center rounded-3xl border py-[6px] ${
                        index === 0
                          ? `border-theme-greenlighter bg-theme-green-lighter`
                          : `border-theme-orange`
                      }`}
                    >
                      <span
                        className={`count w-14 text-center text-h4 leading-[72px] ${
                          index === 0 ? `text-theme-green` : `text-theme-orange`
                        }`}
                      >
                        0{index + 1}
                      </span>
                      <div
                        className={`mr-6 w-0.5 flex-shrink-0 self-stretch bg-theme-orange ${
                          index === 0 ? `bg-theme-green` : `bg-theme-orange`
                        }`}
                      ></div>
                      <div
                        className={`max-w-[204px] text-normal tracking-[-0.016em]`}
                      >
                        {step?.addTeamMember ? (
                          <div>
                            <img
                              src={
                                accountManager?.featuredImage?.node
                                  ?.mediaItemUrl
                              }
                              alt={`${accountManager?.title} photo`}
                              className={`mx-auto my-4 h-24 w-24 rounded-full`}
                            />
                            <span>{parse(text ? text : step.text)}</span>
                          </div>
                        ) : (
                          <span className="font-bold">
                            {text ? text : step.text}
                          </span>
                        )}
                      </div>
                    </div>
                  )
                })}
              </>
            }
          />
        </Wrapper>
        <Spacer top={`yellow`} bottom={`black`} />
      </div>
    </>
  )
}
