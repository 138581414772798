import React, { useCallback, useEffect, useState } from "react"
import { Field } from "~/lib/types"
import { FaTrash } from "react-icons/fa"
import Button from "../reusable/Button"
import { usePostFormStore } from "~/lib/stores/forms"

type VideoInputProps = Field & {
  name: string | undefined
  type: string | (string & {}) | undefined
  value: string | number | readonly string[] | undefined
  required?: boolean | undefined
}

const VideoInput = ({
  name,
  value = "",
  description,
  required,
  type,
}: VideoInputProps) => {
  if (value === null) value = ""

  const [originalValue, setOriginalValue] = useState(value)

  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  const [videoUrlTemp, setVideoUrlTemp] = useState("")
  const [videoUrl, setVideoUrl] = useState("")
  const hasVideoUrl = Boolean(videoUrl)

  const handleVideoUrlReset = useCallback(() => {
    setVideoUrlTemp("")
    setVideoUrl("")
  }, [])

  useEffect(() => {
    setVideoUrl(value)
    setVideoUrlTemp(value)
  }, [value])

  // on change, update the stored fields
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setVideoUrlTemp(value)

    // if the field is dirty, add it to the dirtyFields array
    if (value !== "" && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if value is same as original value, remove it from the dirtyFields array
    if (value === originalValue) {
      removeDirtyField(name)
    }
  }

  function handleClick() {
    // check if videoUrlTemp is a valid youtube url or vimeo url
    if (
      // youtube
      videoUrlTemp.match(
        /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/
      ) ||
      // vimeo
      videoUrlTemp.match(
        /^(https?:\/\/)?(www\.)?(vimeo\.com)\/([a-z]*\/)*(\d+)[?]?.*/
      )
    ) {
      setVideoUrl(videoUrlTemp)
    } else {
      alert("Please enter a valid youtube or vimeo url")
    }
  }

  return (
    <div
      className={`vs-shadow mb-8 block overflow-hidden rounded-3xl @container/video-input`}
    >
      <div className={`bg-theme-yellow px-8 pt-9 pb-8 @md:px-10`}>
        {description && (
          <p className={`mb-5 text-caption @md:text-normal`}>{description}</p>
        )}
        <div className={`flex flex-col gap-6 @md:flex-row @md:items-end`}>
          <div className={`flex-1`}>
            <p className={`mb-2 block text-caption`}>Video Embed URL</p>
            <input
              id={name}
              type={type}
              name={name}
              required={required}
              value={videoUrlTemp}
              onChange={onChange}
              className={`input vs-shadow-light rounded-2xl bg-theme-white p-5 text-caption text-theme-gray-mid`}
            />
          </div>
          <div className="@max-md:mr-auto">
            <Button
              type={"button"}
              text={"Embed"}
              buttonColor={"orange"}
              disabled={hasVideoUrl && !videoUrlTemp}
              onClick={handleClick}
              className="mt-1 mb-0"
            />
          </div>
        </div>
      </div>
      {videoUrl && (
        <div className={`bg-theme-white p-4 md:py-5 md:px-11`}>
          <div className="flex w-full items-center md:justify-between">
            <p className="mr-1 flex-1 font-bold max-md:text-caption">
              {videoUrl}
            </p>
            <button
              type="button"
              className={`rounded-full bg-theme-orange p-4 [&_svg]:h-5 [&_svg]:w-5 [&_svg]:md:h-6 [&_svg]:md:w-6`}
              onClick={handleVideoUrlReset}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoInput
