import { gql, useQuery } from "@apollo/client"

const useSupplierCategory = (slug: string) => {
  const { data, loading, error } = useQuery(
    gql`
      query getSupplierCategories($slug: ID!) {
        supplierCategory(id: $slug, idType: SLUG) {
          id
          name
          suppliers {
            nodes {
              id
              title
              slug
              supplierDetails {
                description
                profilePicture {
                  fileName
                  altText
                }
                heroBanner {
                  fileName
                  altText
                }
              }
            }
          }
        }
      }
    `,
    {
      variables: {
        slug,
      },
    }
  )

  return {
    data: data?.supplierCategory,
    loading,
    error,
  }
}

export default useSupplierCategory
