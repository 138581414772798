import React from "react"
import useTemplateContent from "~/hooks/useTemplateContent"
import useUser from "~/hooks/useUser"
import { replaceEmail } from "~/utils/helpers"
import Wrapper from "../reusable/Wrapper"
import { ThankYouWrapper } from "../ThankYou"

const Review = () => {
  const { user } = useUser()
  const content = useTemplateContent("")

  const text = user.emailAddress
    ? replaceEmail(content.text, user.emailAddress)
    : content.text

  return (
    <Wrapper>
      <ThankYouWrapper
        leftContent={
          <>
            <h2 className="text-h2">{content.title}</h2>
            <p>{text}</p>
          </>
        }
        rightContent={
          <>
            <div className="space-y-2">
              {content.steps.map((step: { text: string }, index: number) => (
                <div key={index} className="flex items-center gap-4">
                  <p>{index + 1}</p>
                  <p>{step.text}</p>
                </div>
              ))}
            </div>
          </>
        }
      />
    </Wrapper>
  )
}

export default Review
