export const formFieldSets = [
  {
    name: "businessDetails",
    label: "Business Details",
    fields: [
      {
        name: "companyName",
        label: "Business Name",
        type: "text",
        value: "",
        required: true,
        dataField: "title",
      },
      {
        name: "email",
        label: "Email Address",
        type: "email",
        value: "",
        required: true,
        dataField: "supplierDetails.emailAddress",
      },
      {
        name: "businessCategory",
        label: "Business Category",
        type: "categories",
        placeholder: "Select Category",
        dataField: "supplierCategories",
        required: false,
      },
      {
        name: "profilePicture",
        label: "Brand Logo",
        type: "file",
        value: "",
        required: false,
        tip: "Recommended dimensions for profile images are 400x400 pixels. JPEG, JPG, PNG are accepted file formats with a maximum file size of 1MB",
        dataField: "supplierDetails.logo",
      },
      {
        name: "memberBenefits",
        label: "Member Benefits",
        type: "benefits",
        value: "",
        required: false,
        dataField: "supplierDetails.memberBenefits",
      },
    ],
  },
  {
    name: "content",
    label: "Content",
    fields: [
      {
        name: "introduction",
        label: "Introduction",
        type: "wysiwyg",
        value: "",
        required: false,
        dataField: "supplierDetails.introduction",
        tip: "This text appears at the top of your full supplier profile.  It should be a summary of your product/service and include your offer/s to VetShare members",
      },
      {
        name: "description",
        label: "Description",
        type: "wysiwyg",
        value: "",
        required: false,
        dataField: "supplierDetails.description",
        tip: "This text appears in your full supplier profile.  Put as much detail in this section as you like about your complany and what it offers VetShare members",
      },
      {
        name: "termsAndConditions",
        label: "Terms and Conditions",
        type: "wysiwyg",
        value: "",
        required: false,
        dataField: "supplierDetails.termsAndConditions",
        tip: "This text appears at the foot of your full supplier profile. Please detail any terms and conditions that apply to your offers to VetShare members",
      },
    ],
  },
  {
    name: "media",
    label: "Media",
    fields: [
      {
        name: "heroBanner",
        label: "Banner Image",
        type: "file",
        value: "",
        required: false,
        tip: "This image appears next to your brand logo on your listing tile in the directory and at the top of your profile page. Recommended dimensions for hero banners are 1920x1080 pixels. JPEG, JPG, PNG are accepted file formats with a maximum file size of 1MB",
        dataField: "supplierDetails.heroBanner",
      },
      {
        name: "documents",
        label: "Documents & Guides",
        type: "file", // TODO add multiple file upload
        value: "",
        limit: 4,
        required: false,
        tip: "PDF are accepted file formats with a maximum file size of 25mb. Maximum of 4 documents can be uploaded",
        dataField: "supplierDetails.documents",
      },
      {
        name: "videoUrl",
        label: "Video Embed",
        type: "video",
        value: "",
        required: false,
        description:
          "Paste a URL link to the video content you want to display on your profile",
        dataField: "supplierDetails.videoUrl",
      },
    ],
  },
]
