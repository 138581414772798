import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function PreviewImage({ image, fallbackUrl, alt, className }) {

  // Use the gatsby image if available
  let displayImage = null
  if (image) {
    if (image.childImageSharp) {
      displayImage = (
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={alt ? alt : ''}
          className={className}
        />
      )
    } else {
      // Gifs do not have a childImageSharp
      displayImage = (
        <div className={`gatsby-image-wrapper ${className}`}>
          <img
            src={image.publicURL}
            alt={alt}
            style={{
              width: "100%",
              position: "relative",
            }}
          />
        </div>
      )
    }
  } else {
    // This is a preview image
    displayImage = (
      <div className={`gatsby-image-wrapper ${className}`}>
        <img
          src={fallbackUrl}
          alt={alt}
          style={{
            width: "100%",
            position: "relative",
          }}
        />
      </div>
    )
  }

  return displayImage
}