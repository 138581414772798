import React, { useState } from "react"
import { usePostFormStore } from "~/lib/stores/forms"

type Props = {
  name: string
  value: string | number | readonly string[] | undefined
  required?: boolean | undefined
  disabled?: boolean | undefined
}

const Textarea = ({ name, required, value, disabled }: Props) => {
  const [originalValue, setOriginalValue] = useState(value)
  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  // on change, update the stored fields
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target

    // if the field is dirty, add it to the dirtyFields array
    if (value !== "" && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if value is same as original value, remove it from the dirtyFields array
    if (value === originalValue) {
      removeDirtyField(name)
    }
  }
  return (
    <textarea
      id={name}
      className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
      name={name}
      required={required}
      defaultValue={value}
      disabled={disabled}
      onChange={onChange}
    />
  )
}

export default Textarea
