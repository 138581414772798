import React, { useState } from "react"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import { useForm } from "react-hook-form"
import TagManager from "react-gtm-module"
import useFormInfo from "~/hooks/useFormInfo"
import { isEmail } from "~/utils/helpers"
import { FormFields, SUBMIT_FORM } from "./formFields/GravityForms"
import Button from "./reusable/Button"
import useTemplateContent from "~/hooks/useTemplateContent"

const ContactUsForm = ({ formRef }: { formRef?: string }) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")
  const formId = useTemplateContent(formRef || "contactUsForm")
  const form = useFormInfo(formId)
  const { register, handleSubmit, formState } = useForm()

  const tagManagerArgs = {
    gtmId: process.env.GATSBY_GOOGLE_TAG_MANAGER_KEY || "",
  }
  if (tagManagerArgs.gtmId) {
    TagManager.initialize(tagManagerArgs)
  }
  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM, {
    onCompleted: data => {
      setFormSubmitted(true)
      navigate("/contact-us/thank-you", {
        state: { formSubmitted: true, emailAddress: emailAddress },
      })
    },
    onError: error => {
      setFormSubmitted(true)
      navigate("/contact-us/thank-you", {
        state: { formSubmitted: true, emailAddress: emailAddress },
      })
    },
  })

  if (error) {
    console.log(error)
  }

  function onSubmit(data: any) {
    let fields = Object.keys(data).map((item: any, index: number) => {
      if (data[item] !== null) {
        if (data[item].length > 0) {
          if (isEmail(data[item])) {
            setEmailAddress(data[item])
            return {
              id: parseInt(item),
              emailValues: {
                value: data[item],
              },
            }
          }
          return {
            id: parseInt(item),
            value: data[item]?.toString(),
          }
        }
      }
    })

    // remove null values
    fields = fields.filter(item => item !== undefined)

    TagManager.dataLayer({
      dataLayer: {
        event: "form_submit",
        event_category: "form",
        event_action: "form_submission",
        event_label: `Form ID: ${formId} - ${form?.title}`,
      },
    })

    submitForm({
      variables: {
        formId: formId,
        fieldValues: fields,
      },
    })
  }

  return (
    <div>
      {formSubmitted && form?.formFields ? (
        <div>Thanks?</div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {form?.formFields?.map((field: any) => {
            const Field = FormFields[field.type as keyof typeof FormFields]
            // if (!Field) return null
            return <Field key={field?.id} {...field} register={register} />
          })}
          {/* {error && (
            <div className="text-xs mb-4 flex items-center justify-between rounded-lg border-2 border-red-500 bg-red-200 py-2 px-4 font-semibold italic text-red-500">
              <p>Oops, something went wrong! Try again</p>
            </div>
          )} */}
          <div className="flex items-center justify-between">
            <div className="font-semibold">
              <span className="text-theme-orange">*</span> Required Fields
            </div>
            <Button
              type="submit"
              text={loading ? "Sending..." : "Send message"}
              buttonColor={"orange"}
            />
          </div>
        </form>
      )}
    </div>
  )
}

export default ContactUsForm
