import React from "react"

const Status = ({ post }: { post: any }) => {
  if (!post?.newsPost) return null

  const { publishedStatus, approvalStatus, inReview, expiryDate } =
    post?.newsPost

  const published = () => {
    switch (expiryDate) {
      // expired
      case expiryDate < new Date():
        return "expired"

      // not expired
      default:
        switch (inReview) {
          // in review
          case true:
            return "inReview"

          // not in review
          default:
            switch (publishedStatus) {
              // published
              case true:
                switch (approvalStatus) {
                  // published and approved
                  case true:
                    return "published"

                  // published and not approved
                  default:
                    return "unapproved"
                }

              // draft
              case false || null:
                switch (approvalStatus) {
                  // draft and approved
                  case true:
                    return "draft"

                  // draft and not approved
                  default:
                    // return "unapproved"
                    return "draft"
                }

              // fallback
              default:
                return ""
            }
        }
    }
  }

  const statuses = {
    published: "Approved & Published",
    draft: "Draft",
    expired: "Ended",
    inReview: "In Review",
    unapproved: "Unapproved",
  }

  const statusColor = {
    published: "text-theme-green",
    draft: "text-theme-green-light",
    expired: "text-red-500",
    inReview: "text-theme-orange",
    unapproved: "text-theme-orange",
  }

  return (
    <>
      <p
        className={`text-caption font-bold uppercase tracking-wider ${
          statusColor[published() as keyof typeof statusColor]
        }`}
      >
        {statuses[published() as keyof typeof statuses]}
      </p>
    </>
  )
}

export default Status
