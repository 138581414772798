import React, { useEffect, useRef, useState } from "react"
import { Field } from "~/lib/types"
import "quill/dist/quill.snow.css"
import { usePostFormStore } from "~/lib/stores/forms"

let ReactQuill: any
if (typeof window !== "undefined") {
  ReactQuill = require("react-quill")
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  ],
}

const quillFormats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "script",
  "size",
  "header",
  "list",
  "indent",
  "link",
  "color",
  "background",
  "align",
]

type TextEditProps = Field & {
  name: string | undefined
}

const normalise = (value: string) => {
  return value.replace(/[\r\n]/gm, "").trim()
}

const TextEdit = ({ name, required, value }: TextEditProps) => {
  const [originalValue, setOriginalValue] = useState(value)

  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  const [textAreaValue, setTextAreaValue] = useState(value || "")
  const quillRef = useRef()

  // unmount and remount Form component
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [value])

  useEffect(() => {
    if (!value) return
    setTextAreaValue(value)
    setOriginalValue(value)
  }, [value])

  const handleChange = (v: string) => {
    if (!mounted) return
    setTextAreaValue(v)

    // if value has changed, add to dirtyFields
    if (
      v !== "" &&
      !dirtyFields.includes(name) &&
      normalise(v) !== normalise(value)
    ) {
      addDirtyField(name)
    }

    // if value is same as original value, remove it from the dirtyFields array
    if (normalise(v) === normalise(originalValue)) {
      removeDirtyField(name)
    }
  }

  if (typeof window === "undefined") {
    return null
  }

  return (
    <>
      <div className="mb-8 w-full rounded-2xl bg-theme-white">
        <ReactQuill
          ref={quillRef}
          onChange={handleChange}
          value={textAreaValue}
          defaultValue={value ? value : ""}
          modules={modules}
          formats={quillFormats}
          className="w-full [&_.ql-toolbar]:rounded-t-2xl [&_.ql-toolbar]:bg-theme-gray-light [&_.ql-container]:rounded-b-2xl [&_.ql-editor]:min-h-[250px]"
        />
      </div>
      <textarea
        id={name}
        className="sr-only"
        name={name}
        value={textAreaValue}
        onChange={() => {}}
        required={required}
      />
    </>
  )
}

export default TextEdit
