import { useQuery } from "@apollo/client"
import { GET_SUPPLIER_NEWS_ITEM } from "~/lib/queries"

const useSupplierNewsItem = (slug: string) => {
  const { data, loading, error } = useQuery(GET_SUPPLIER_NEWS_ITEM, {
    variables: { slug: slug },
  })

  return { data: data?.supplierNews, loading, error }
}

export default useSupplierNewsItem
