import React from "react"
import Page from "../reusable/Page"
import Wrapper from "../reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import SupplierNewsPosts from "~/components/SupplierNewsPosts"
import LinkedAccountManager from "../LinkedAccountManager"
import useUser from "~/hooks/useUser"

const News = () => {
  const { user } = useUser()
  return (
    <Page title="Latest news &amp; offers">
      <header className={`bg-theme-green-light pt-10 md:pt-24`}>
        <Wrapper>
          <h1 className="text-h2 font-bold tracking-[-0.024em] md:text-h1">
            Latest News &amp; Offers
          </h1>
        </Wrapper>
        <Spacer top={`green-light`} bottom={`white`} />
      </header>
      <Wrapper className={`-mt-8 pb-8 md:pb-16`}>
        <SupplierNewsPosts show={6} />
        {/* get list of supplier news with pagination */}
      </Wrapper>
      {user?.userGroup !== "suppliers" ? (
        <>
          <Spacer top="white" bottom="orange-lighter" />
          <LinkedAccountManager
            postcode={user?.linkedGroupData?.memberDetails?.postcode || ""}
          />
          <div className={`relative z-[2]`}>
            <Spacer top="transparent" bottom="black" />
          </div>
        </>
      ) : (
        <div className={`relative z-[2]`}>
          <Spacer top={`white`} bottom={`black`} />
        </div>
      )}
    </Page>
  )
}

export default News
