import React from "react"
import { Helmet } from "react-helmet"

type PageProps = {
  title?: string
  children: React.ReactNode
}
const Page = ({ title, children }: PageProps) => {
  return (
    <>
      <Helmet title={title} />
      {children}
    </>
  )
}

export default Page
