import React from "react"

export default function Delete() {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 24 24" }}
    >
      <path d="M21.1,4.1h-4V2.9c0-1.6-1.3-2.9-2.9-2.9H9.8C8.2,0,6.9,1.3,6.9,2.9v1.2h-4c-0.6,0-1.1,0.5-1.1,1.2c0,0.6,0.5,1.2,1.1,1.2h0.6v13.7c0,2.1,1.7,3.8,3.8,3.8h9.5c2.1,0,3.8-1.7,3.8-3.8V6.5h0.6c0.6,0,1.2-0.5,1.2-1.2S21.8,4.1,21.1,4.1z M9.2,2.9L9.2,2.9c0-0.3,0.3-0.6,0.6-0.6h4.4c0.3,0,0.6,0.3,0.6,0.6v1.2H9.2V2.9z M18.2,20.2c0,0.8-0.7,1.5-1.5,1.5H7.3c-0.8,0-1.5-0.7-1.5-1.5V6.5h12.5V20.2z" />
      <path d="M10.1,9.1v9.5c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3V9.1c0-0.7,0.6-1.3,1.3-1.3C9.5,7.8,10.1,8.4,10.1,9.1z" />
      <path d="M16.5,9.1v9.5c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3V9.1c0-0.7,0.6-1.3,1.3-1.3C15.9,7.8,16.5,8.4,16.5,9.1z" />
    </svg>
  )
}
