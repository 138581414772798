import React from "react"
import { Link } from "gatsby"
import { useMutation, gql } from "@apollo/client"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import { formatUser } from "~/hooks/useAuth"
import Page from "../reusable/Page"
import useTemplateContent from "~/hooks/useTemplateContent"
import { signUpPage } from "~/lib/constants"
import Seo from "../SEO"
import Button from "../reusable/Button"
import clipboard from "../../images/icon-clipboard.png"
import { useUserStore } from "~/lib/stores/user"
import Dot from "../reusable/Dot"

const LOG_IN = gql`
  mutation logIn($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      refreshToken
      authToken
      user {
        id
        username
        firstName
        lastName
        email
        userGroup {
          group
          linkedSupplier {
            ... on Supplier {
              id
              title
              slug
              databaseId
              supplierDetails {
                emailAddress
                profilePicture {
                  fileName
                }
              }
            }
          }
          linkedMember {
            ... on Member {
              id
              title
              slug
              memberDetails {
                clinicId
                postcode
              }
            }
          }
        }
      }
    }
  }
`

export default function LogIn() {
  const setUserLoggedIn = useUserStore(state => state.setUserLoggedIn)
  const setUser = useUserStore(state => state.setUser)
  const setUserSessionStart = useUserStore(state => state.setUserSessionStart)

  const [logIn, { loading, error }] = useMutation(LOG_IN, {
    onCompleted(data) {
      if (!data.login.refreshToken) return
      // check if browser supports localStorage
      try {
        setUserSessionStart(new Date())
        setUser(formatUser(data.login))
        setUserLoggedIn(true)
      } catch (error) {
        console.log("error")
        console.error(error)
      }
    },
  })

  const [
    text,
    membersText,
    membersFeatures,
    suppliersText,
    suppliersFeatures,
    meta,
  ] = useTemplateContent([
    "logInText",
    "logInMembersText",
    "logInMembersFeatures",
    "logInSuppliersText",
    "logInSuppliersFeatures",
    "logInMeta",
  ])

  const errorMessage = error?.message || ""
  const isEmailValid =
    !errorMessage.includes("empty_email") &&
    !errorMessage.includes("empty_username") &&
    !errorMessage.includes("invalid_email") &&
    !errorMessage.includes("invalid_username")
  const isPasswordValid =
    !errorMessage.includes("empty_password") &&
    !errorMessage.includes("incorrect_password")

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const data = new FormData(event.currentTarget) as FormData
    const { email, password } = Object.fromEntries(data)

    logIn({
      variables: {
        username: email,
        password,
      },
    }).catch(error => {
      console.error(error)
    })
  }

  return (
    <Page title={`Login | Vetshare`}>
      <Seo title={meta?.title} description={meta?.description} />
      <Wrapper className={``}>
        <Dot />
        <div
          className={`login-form flex-set-0-0 z-[2] mx-auto mb-5 max-w-[545px] basis-almost-full rounded-[32px] bg-theme-yellow px-14 pt-10`}
        >
          <h1 className={`mb-6 text-h3 font-bold md:text-h2`}>Login</h1>
          <p className={`mb-4 text-small`}>{text}</p>
          <form method="post" onSubmit={handleSubmit}>
            <fieldset disabled={loading} aria-busy={loading}>
              <label
                htmlFor="log-in-email"
                className={`mb-2 text-caption uppercase text-black`}
              >
                Email
              </label>
              <input
                className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
                id="log-in-email"
                type="email"
                name="email"
                autoComplete="username"
                required
              />
              <label
                htmlFor="log-in-password"
                className={`mb-2 text-caption uppercase text-black`}
              >
                Password
                <Link
                  to="/portal/forgot-password"
                  className={`float-right ml-8 font-bold normal-case text-theme-orange underline`}
                >
                  Forgot your password?
                </Link>
              </label>
              <input
                className={`input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`}
                id="log-in-password"
                type="password"
                name="password"
                autoComplete="current-password"
                required
              />
              {!isEmailValid ? (
                <p className="error-message">
                  Invalid email. Please try again.
                </p>
              ) : null}
              {!isPasswordValid ? (
                <p className="error-message">
                  Invalid password. Please try again.
                </p>
              ) : null}
            </fieldset>
            <div className="my-4">
              <Button
                type={"submit"}
                text={loading ? "Logging in..." : "Log in"}
                buttonColor={"orange"}
                className={`!mx-auto !table`}
              />
            </div>
            <p
              className={`-mx-14 border-t-2 border-theme-black/50 p-6 text-center text-small`}
            >
              Don&#39;t have an account yet?{" "}
              <Link
                to={signUpPage}
                className={`font-bold text-theme-orange underline`}
              >
                Sign up now
              </Link>
            </p>
          </form>
        </div>
      </Wrapper>
      <Spacer top={`transparent`} bottom={`green-lighter`} />
      <div className={`bg-theme-green-lighter py-10 md:py-20`}>
        <Wrapper>
          <h2
            className={`flex-set-0-0 mx-auto mb-8 basis-almost-full text-center text-h3 md:text-h2`}
          >
            Once you've logged in
          </h2>
          <div
            className={`cards flex-set-0-0 mx-auto grid basis-almost-full grid-cols-1 gap-6 md:grid-cols-2 lg:basis-5/6`}
          >
            <FeatureCard
              title="Members can:"
              content={{
                text: membersText,
                features: membersFeatures,
              }}
            />
            <FeatureCard
              title="Suppliers can:"
              content={{
                text: suppliersText,
                features: suppliersFeatures,
              }}
            />
          </div>
        </Wrapper>
      </div>
      <Spacer top={`green-lighter`} bottom={`black`} />
    </Page>
  )
}

const FeatureCard = ({
  title,
  image,
  content,
}: {
  title: string
  image?: any
  content: any
}) => {
  return (
    <div
      className={`card vs-shadow rounded-[32px] bg-theme-white py-8 px-10 lg:py-10 xl:px-[72px]`}
    >
      <img src={clipboard} className={`mx-auto mb-8 w-[115px]`} />
      <h3 className={`mb-3 text-h4 font-bold line-clamp-1 md:text-h3`}>
        {title}
      </h3>
      <p className={`bold mb-5 text-small line-clamp-3 md:min-h-[4.5em]`}>
        {content.text}
      </p>
      {content?.features ? (
        <ul
          className={`marker:l-0 relative mx-auto block w-full max-w-wrapper pl-7 pr-3 marker:w-8 marker:text-theme-orange`}
        >
          {content.features.slice(0, 3).map((feature: any, index: number) => {
            return (
              <li
                key={index}
                className={`relative mb-4 block pl-4 text-normal before:absolute before:top-[6px] before:-left-4 before:h-3 before:w-3 before:rounded-full before:bg-theme-orange before:content-[""] max-xl:md:min-h-[2.67em]`}
              >
                {feature.text}
              </li>
            )
          })}
        </ul>
      ) : null}
    </div>
  )
}
