import { useMemo, useState } from "react"

const usePagination = ({
  items,
  show = 10,
  filtered = false,
}: {
  items: any[]
  show: number
  filtered: boolean
}) => {
  const [activePage, setActivePage] = useState(1)

  const activeItems = useMemo(
    () =>
      filtered
        ? items?.slice(1 * show - show, 1 * show)
        : items?.slice(activePage * show - show, activePage * show),
    [items, activePage, show, filtered]
  )

  const paginationPages = useMemo(
    () => Math.ceil(items?.length / show),
    [items, show, filtered]
  )

  return [activePage, activeItems, paginationPages, setActivePage]
}

export default usePagination
