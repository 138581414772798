import React, { useCallback, useContext, useState } from "react"
import { useDropzone } from "react-dropzone"
import useUser from "~/hooks/useUser"
import { restApiUrl } from "~/lib/constants"
import { createMediaFromFile } from "~/lib/uploads"
import { bytesToSize } from "~/utils/helpers"
import fileIcon from "~/images/file-icon.png"
import { FaTrash } from "react-icons/fa"
import { ProfileContext } from "../routes/SuppliersEdit"
import Loader from "../Loader"
import { Slide, toast } from "react-toastify"
import { GET_SUPPLIER } from "~/lib/queries"
import { useUpdateSupplier } from "~/hooks/supplier"
import { usePostFormStore } from "~/lib/stores/forms"

interface SupplierDetails {
  [key: string]: any
}

function getDetailFromSupplier(supplierDetails: SupplierDetails, name: string) {
  if (!supplierDetails) {
    throw new Error("supplierDetails is required")
  }

  if (!name) {
    throw new Error("name is required")
  }

  if (typeof supplierDetails !== "object") {
    throw new Error("supplierDetails must be an object")
  }

  if (!(name in supplierDetails)) {
    throw new Error(`supplierDetails.${name} is required`)
  }

  return supplierDetails[name]
}

const ImageUpload = ({
  name,
  limit = 1,
  formSaved,
}: {
  name: string
  value: File[]
  example?: React.ReactNode
  limit: number
  formSaved: boolean
}) => {
  const { user } = useUser()
  const [uploading, setUploading] = useState(false)
  const [files, setFiles] = useState<File[]>([])
  const [preloaded, setPreloaded] = useState(false)
  const profileContext = useContext(ProfileContext)
  const [filesUploaded, setFilesUploaded] = useState(false)
  const { updateSupplier } = useUpdateSupplier()
  const [media, setMedia] = useState(new Set())
  const { addDirtyField } = usePostFormStore()

  // get image if already uploaded
  if (profileContext?.data?.supplierDetails && !preloaded) {
    const imageCurrentData = getDetailFromSupplier(
      profileContext?.data?.supplierDetails,
      name
    )
    if (imageCurrentData) {
      setFiles([imageCurrentData])
      setMedia(new Set([imageCurrentData.databaseId]))
    }
    setPreloaded(true)
  }

  // onDrop upload file in the background
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (!user.token || !restApiUrl) return
      let newFiles: any[] = []

      const uploadedFiles = new Promise<void>((resolve, reject) => {
        acceptedFiles.forEach(async (file: any, index: number) => {
          setUploading(true)
          const res = await createMediaFromFile(file, user.token, restApiUrl)

          if (res.ok) {
            setUploading(false)
            const data = await res.json()
            const mediaId = data?.id
            setMedia(prev => new Set(prev).add(mediaId))

            // get file extension from mime type
            const fileExtension = data?.mime_type?.split("/")[1]

            // id to File object
            const fileObj = {
              databaseId: mediaId,
              fileSize: data.media_details.filesize || file.size,
              fileName: `${data.title.rendered}.${fileExtension}` || file.name,
            }

            newFiles.push(fileObj)
          }
          if (!res.ok) {
            setUploading(false)
            console.error("error")
          }

          if (index === acceptedFiles.length - 1) {
            resolve()
          }
        })
      })

      uploadedFiles.then(() => {
        // after all files are uploaded, set the files state
        setFiles([...(files || []), ...(newFiles || [])])
        setFilesUploaded(true)
        addDirtyField(name)
      })
    },
    [files, user.token]
  )

  const { getRootProps, getInputProps, acceptedFiles, isDragActive, open } =
    useDropzone({
      onDrop,
      noClick: true,
      accept: { "image/jpeg": [], "image/png": [] },
    })

  const handleDelete = (fileToDelete: number) => {
    // remove the file from the files array
    const filesWithoutDeleted = files.filter(
      item => item.databaseId !== fileToDelete
    )
    setFiles(filesWithoutDeleted)
    setMedia(prev => {
      const newSet = new Set(prev)
      newSet.delete(fileToDelete)
      return newSet
    })

    if (profileContext?.data?.id) {
      updateSupplier({
        variables: {
          id: profileContext?.data?.id,
          approvalStatus: false,
          [name]: "",
        },
        refetchQueries: [
          {
            query: GET_SUPPLIER,
            variables: { slug: profileContext?.data?.slug },
          },
        ],
        onCompleted() {
          toast.success("File deleted", {
            transition: Slide,
            autoClose: 3000,
            hideProgressBar: true,
            onClose: () => {},
          })
        },
      })
    }
  }

  return (
    <div className="relative @container">
      {/* add hidden input to save mediaId */}
      <input
        type="hidden"
        name={name}
        value={`${Array.from(media)}`
          .split(",")
          .filter((id: string, index: number, array: string[]) => {
            return array.indexOf(id) === index
          })
          .toString()}
      />

      <div className="vs-shadow mb-12 rounded-3xl bg-theme-white p-4 @sm:p-7">
        {!filesUploaded && (files?.length < limit || !files) ? (
          <div
            className={`group grid aspect-[5/2] flex-1 place-items-center rounded-md border-2 border-dashed border-orange-500/70 transition hover:border-orange-500/100 ${
              files?.length >= limit
                ? "pointer-events-none opacity-50"
                : "cursor-pointer"
            }`}
            {...getRootProps({
              onClick: open,
            })}
          >
            <input {...getInputProps()} />
            <p className="px-2 text-center">
              {isDragActive
                ? "Drop the files here ..."
                : "Drag 'n' drop some files here, or click to select files"}
            </p>
          </div>
        ) : filesUploaded && files?.length < limit && !formSaved ? (
          <div className="rounded-md bg-theme-orange-light/20 p-4 text-center text-caption font-medium text-theme-orange">
            Save edits to upload more files
          </div>
        ) : null}

        {uploading ? (
          <div className="my-6 flex items-center justify-center gap-4 opacity-60">
            Uploading... <Loader loading={uploading} size={20} />
          </div>
        ) : (
          <FileList files={files} onDelete={handleDelete} />
        )}
      </div>
    </div>
  )
}

export default ImageUpload

interface SingleFile extends File {
  path?: string
  fileName?: string
  fileSize?: number
  title?: string
  databaseId?: number
  file?: any
}

interface FileListProps {
  files: SingleFile[]
  onDelete: (file: any) => void
}

const FileList = ({ files, onDelete }: FileListProps) => {
  if (!files || files.length <= 0) return null

  const file = files[0]

  return (
    <ul>
      <li className={`p-4`}>
        <div className={`flex items-center`}>
          <div className={`icon relative w-6 md:w-10`}>
            <img src={fileIcon} className={`w-6 md:w-10`} />
          </div>
          <div className={`flex-1 px-8`}>
            <p className={`text-caption font-bold @sm:text-small`}>
              {file.fileName}
            </p>
            <p className={`mt-2 text-caption text-theme-gray-mid @sm:mt-0`}>
              {bytesToSize(file.fileSize || 0)}
            </p>
          </div>
          <button
            type="button"
            className={`rounded-full bg-theme-orange p-4 transition hover:bg-theme-orange-light`}
            onClick={() => onDelete(file.databaseId)}
          >
            <FaTrash className="h-5 w-5 md:h-6 md:w-6" />
          </button>
        </div>
      </li>
    </ul>
  )
}
