import { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Supplier } from "~/graphql/generated/graphql"

const useSuppliersStatic = (filter?: string) => {
  const data = useStaticQuery(graphql`
    query getSuppliers {
      allWpSupplier {
        edges {
          node {
            id
            title
            slug
            supplierDetails {
              description
              heroBanner {
                id
                mediaItemUrl
                fileName
              }
              profilePicture {
                id
                mediaItemUrl
                fileName
              }
              memberBenefits
              featured
            }
            supplierCategories {
              nodes {
                id
                name
                slug
              }
            }
            supplierKeywords {
              nodes {
                id
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  const suppliers: Supplier[] = useMemo(() => {
    if (data?.allWpSupplier?.edges) {
      return data.allWpSupplier.edges.map((edge: any) => edge?.node)
    }
    return []
  }, [data])

  const filteredSuppliers = useMemo(() => {
    if (filter) {
      // filter by category slug
      return suppliers.filter(supplier => {
        return supplier.supplierCategories.nodes.some(
          category => category.slug === filter
        )
      })
    }
    return suppliers
  }, [suppliers, filter])

  return filteredSuppliers
}

export default useSuppliersStatic
