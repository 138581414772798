import { graphql, useStaticQuery } from "gatsby"

const useImages = (image?: string) => {
  const data = useStaticQuery(graphql`
    query GET_ALL_IMAGES {
      allFile(filter: { extension: { in: ["png", "jpg", "jpeg"] } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            publicURL
            base
          }
        }
      }
    }
  `)

  // check if image is a string

  if (image && typeof image === "string") {
    const imageNode = data.allFile.edges.find(
      ({ node }: any) => node.base === image
    )

    return imageNode ? imageNode?.node : null
  }

  if (image === undefined) {
    return data.allFile.edges.map(edge => edge.node)
  }

  return null
}

export default useImages
