import { create } from "zustand"

interface PostFormState {
  dirtyFields: string[]
  setDirtyFields: (dirtyFields: string[]) => void
  addDirtyField: (dirtyField: string) => void
  removeDirtyField: (dirtyField: string) => void
  // catch all values
  values: any
  setValues: (values: any) => void
}

export const usePostFormStore = create<PostFormState>()(set => ({
  dirtyFields: [],
  setDirtyFields: (dirtyFields: string[]) => set({ dirtyFields }),
  addDirtyField: (dirtyField: string) =>
    set(state => ({
      // check if field is already in array
      dirtyFields: state.dirtyFields.includes(dirtyField)
        ? [...state.dirtyFields]
        : [...state.dirtyFields, dirtyField],
    })),
  removeDirtyField: (dirtyField: string) =>
    set(state => ({
      dirtyFields: state.dirtyFields.filter(field => field !== dirtyField),
    })),
  values: {},
  setValues: (values: any) => set({ values }),
}))
