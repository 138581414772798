import React, { createContext, useEffect, useState } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import { ToastContainer, toast, Slide } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import { useSupplier, useUpdateSupplier } from "~/hooks/supplier"
import FormFields from "~/components/FormFields"
import { formFieldSets } from "~/data/supplierFields"
import useUser from "~/hooks/useUser"
import { IoPlay } from "react-icons/io5"
import { FaCheck } from "react-icons/fa"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { Link } from "gatsby"
import { formatDistance } from "date-fns"
import Button from "../reusable/Button"
import Form from "~/utils/Form"
import { cleanUpIds } from "./EditSupplierPost"
import { usePostFormStore } from "~/lib/stores/forms"

const detailTabs = [
  {
    name: "businessDetails",
    label: "Business Details",
  },
  {
    name: "content",
    label: "Content",
  },
  {
    name: "media",
    label: "Media",
  },
]

// create context for page
export const ProfileContext = createContext({
  data: null,
  loading: null,
  error: null,
})

const EditSupplierPage = () => {
  const [successfulSave, setSuccessfulSave] = useState(false)
  const { user } = useUser()
  const slug = user?.linkedGroupData?.slug || ""
  // get current supplier data
  const { data, loading, error } = useSupplier(slug)
  const [formSaved, setFormSaved] = useState(false)
  const [fieldsHaveBeenSet, setFieldsHaveBeenSet] = useState(false)

  // get update supplier mutation
  const {
    updateSupplier,
    data: updateData,
    loading: updating,
    error: updateError,
  } = useUpdateSupplier(slug, data => {
    setFormSaved(true)
    setFieldsHaveBeenSet(false)
    setDirtyFields([])
  })
  const [activeTab, setActiveTab] = useState("businessDetails")
  const [supplierFields, setSupplierFields] = useState(formFieldSets)

  const { dirtyFields, setDirtyFields } = usePostFormStore()

  // TODO: move to onComplete
  useEffect(() => {
    const updatedSuccessfully = Boolean(
      updateData?.updateSupplier?.supplier?.id
    )

    if (updatedSuccessfully) {
      setSuccessfulSave(true)
      toast.success("Supplier updated successfully", {
        transition: Slide,
        autoClose: 3000,
        hideProgressBar: true,
        onClose: () => {
          setSuccessfulSave(false)
        },
      })
    }
    if (updateError) {
      toast.error("Supplier failed to update", {
        transition: Slide,
        autoClose: 3000,
        hideProgressBar: true,
      })
      console.error(updateError)
    }
  }, [updateData, updateError])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const form = event.currentTarget || event.target
    const formData = new FormData(form) as any
    const values = Object.fromEntries(formData)

    if (!values || !data?.id) {
      return
    }

    updateSupplier({
      variables: {
        id: data?.id,
        companyName: values.companyName,
        email: values.email,
        description: values.description,
        introduction: values.introduction,
        memberBenefits: values.memberBenefits,
        termsAndConditions: values.termsAndConditions,
        videoUrl: values.videoUrl,
        heroBanner: cleanUpIds(values.heroBanner),
        profilePicture: cleanUpIds(values.profilePicture),
        documents: cleanUpIds(values.documents),
        documentsTitles: `[${values.documentsTitles}]`,
        businessCategory: values.businessCategory,
        subBusinessCategory: values.subBusinessCategory,
      },
    })
  }

  useEffect(() => {
    window.onpopstate = () => {
      setFieldsHaveBeenSet(false)
    }
  }, [])

  useEffect(() => {
    if (!data || fieldsHaveBeenSet) return
    let fields: any[] = []

    // update supplier fields
    supplierFields.forEach((fieldSet: any) => {
      fieldSet.fields.forEach((field: any) => {
        if (field.dataField) {
          // check if field.dataField has . to separate nested fields
          if (field.dataField.includes(".")) {
            const nestedFields = field.dataField.split(".")
            // for nested fields, get the value from the nested object
            const value = nestedFields.reduce(
              (obj: { [x: string]: any }, key: string | number) => obj[key],
              data
            )
            field.value = value
          } else {
            field.value = data[field.dataField]
          }
        }
      })
      fields.push(fieldSet)
    })
    setFieldsHaveBeenSet(true)
    setSupplierFields(fields)
  }, [data, fieldsHaveBeenSet])

  const lastEdited = data?.modified
    ? formatDistance(new Date(data?.modified), new Date(), { addSuffix: true })
    : false

  // use ProfileContext to pass data to children

  const hasDirtyFields = dirtyFields && dirtyFields.length > 0

  return (
    <ProfileContext.Provider value={{ data, updating, error }}>
      <div className={`bg-theme-yellow-light pt-6`}>
        <Wrapper>
          <ToastContainer />
          <Form submitHandler={handleSubmit}>
            <div className="items-start md:flex">
              <div
                className={`vs-shadow-light flex-set-0-0 top-8 mx-3 mb-8 h-fit basis-almost-1/4 rounded-3xl bg-theme-white pb-8 overflow-hidden md:sticky`}
              >
                <div
                    className={`text-sm bg-amber-500/30 text-center font-semibold uppercase tracking-wide text-amber-800 transition-all overflow-hidden ${
                      hasDirtyFields ? "h-14 p-4" : "opacity-0 h-0"
                    }`}
                  >
                    Unsaved changes
                  </div>
                <h1 className="mt-8 mb-7 px-11 text-h4 font-bold md:text-h3">
                  Edit Profile
                </h1>

                <p className={`mb-6 block px-11 text-small`}>
                  Last Edited: {lastEdited}
                </p>

                {detailTabs.map(({ name, label }) => (
                  <button
                    key={name}
                    onClick={() => setActiveTab(name)}
                    className={`relative mb-4 block w-full px-12 py-4 text-left text-normal font-bold ${
                      activeTab === name
                        ? `vs-shadow-light bg-theme-orange-light`
                        : ``
                    }`}
                    type="button"
                  >
                    {label}
                    {activeTab === name && (
                      <i
                        className={`absolute top-[-10px] bottom-[-10px] left-[calc(100%_-_20px)] w-[76px] text-theme-orange-light max-md:hidden [&_svg]:h-full [&_svg]:w-full`}
                      >
                        <IoPlay />
                      </i>
                    )}
                  </button>
                ))}
                <Button
                  type={"submit"}
                  text={
                    updating ? (
                      "Saving..."
                    ) : (
                      <>
                        <i
                          className={`mr-2 inline-block translate-y-[-2px] align-middle`}
                        >
                          <FaCheck />
                        </i>
                        Save Edits
                      </>
                    )
                  }
                  buttonColor={"orange"}
                  disabled={updating || successfulSave}
                  className="!mx-auto !mb-8 !mt-10 !table"
                />

                <Link
                  to={`/portal/suppliers/preview`}
                  className={`mx-auto flex w-fit items-center text-normal font-bold md:mb-2`}
                >
                  <i className={`relative mr-2`}>
                    <span
                      className={`absolute top-2 left-[9px] block h-px w-px rounded-sm bg-theme-black`}
                    />
                    <MdOutlineRemoveRedEye />
                  </i>
                  <span className={`underline`}>Preview Profile</span>
                </Link>
              </div>
              <div className="relative mx-auto basis-almost-7/12 @container/form-parent [&_.text-field]:@md:w-7/12 [&_.email-field]:@md:w-7/12 [&_.categories-field]:@md:w-7/12">
                {loading && (
                  <div className="absolute -inset-2 z-20 flex items-center justify-center text-orange-500 backdrop-blur-sm">
                    <ClipLoader
                      size={120}
                      color={"currentColor"}
                      loading={loading}
                    />
                  </div>
                )}

                {formFieldSets.map(({ name, label, fields }) => (
                  <fieldset
                    key={name}
                    disabled={updating}
                    aria-busy={updating}
                    className={`pt-4 ${
                      activeTab === name ? "block" : "hidden"
                    }`}
                  >
                    <h2 className="mb-10 text-h3 md:text-h2">{label}</h2>
                    <FormFields fields={fields} formSaved={formSaved} />
                  </fieldset>
                ))}
              </div>
            </div>
          </Form>
        </Wrapper>
        <Spacer top={`yellow-light`} bottom={`black`} />
      </div>
    </ProfileContext.Provider>
  )
}

export default EditSupplierPage
