import { useMemo } from "react"
import { gql, useQuery } from "@apollo/client"
import { convertToSlug, postcodePrefix } from "~/utils/helpers"
import useRegions from "./useRegions"

const GET_ACCOUNT_MANAGERS = gql`
  query getAccountManagers {
    accountManagers {
      edges {
        node {
          id
          title
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          details {
            forename
            surname
            emailAddress
            telephone
            region
          }
        }
      }
    }
  }
`

const useAccountManager = (postcode: string) => {
  const regions = useRegions()
  const { data, loading, error } = useQuery(GET_ACCOUNT_MANAGERS)

  const accountManagers = useMemo(
    () =>
      data?.accountManagers?.edges?.map(
        (
          { node }: any // flatten details and remove
        ) => ({
          ...node,
          ...node.details,
          details: undefined,
          thumbnail: node.featuredImage?.node?.mediaItemUrl,
        })
      ),
    [data]
  )

  // find the region that matches the postcode
  const region = useMemo(
    () =>
      regions?.find(({ postcodes }: any) =>
        postcodes?.includes(postcodePrefix(postcode))
      ),
    [regions, postcode]
  )

  // find the account manager that matches the region
  const matchedAccountManager = useMemo(
    () =>
      accountManagers?.find(
        ({ region: accountManagerRegion }: any) =>
          accountManagerRegion?.toLowerCase() === convertToSlug(region?.name)
      ),
    [accountManagers, region]
  )

  const accountManager = useMemo(
    // if no match, return account manager with title "Ross Allen"
    () =>
      !matchedAccountManager
        ? accountManagers?.find(
            ({ title }: { title: string }) => title === "Ross Allen"
          )
        : matchedAccountManager,
    [matchedAccountManager, accountManagers]
  )

  return { data: accountManager, loading, error }
}

export default useAccountManager
