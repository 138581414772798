import type { LookupTable } from "../utils/types"

export const textAlignment: LookupTable = {
  left: "text-left",
  right: "text-right",
  center: "text-center",
}

export const textColors: LookupTable = {
  green: "text-theme-green",
  "green-light": "text-theme-green-light",
  "green-lighter": "text-theme-green-lighter",
  orange: "text-theme-orange",
  "orange-light": "text-theme-orange-light",
  "orange-lighter": "text-theme-orange-lighter",
  yellow: "text-theme-yellow",
  "yellow-light": "text-theme-yellow-light",
  "yellow-lighter": "text-theme-yellow-lighter",
  black: "text-theme-black",
  "gray-mid": "text-theme-gray-mid",
  "gray-light": "text-theme-gray-light",
  white: "text-theme-white",
}

export const bgColors: LookupTable = {
  green: "bg-theme-green",
  "green-light": "bg-theme-green-light",
  "green-lighter": "bg-theme-green-lighter",
  orange: "bg-theme-orange",
  "orange-light": "bg-theme-orange-light",
  "orange-lighter": "bg-theme-orange-lighter",
  yellow: "bg-theme-yellow",
  "yellow-light": "bg-theme-yellow-light",
  "yellow-lighter": "bg-theme-yellow-lighter",
  black: "bg-theme-black",
  "gray-mid": "bg-theme-gray-mid",
  "gray-light": "bg-theme-gray-light",
  white: "bg-theme-white",
}

export const fontSizes: LookupTable = {
  caption: "text-caption font-medium",
  small: "text-small",
  normal: "text-normal",
  h1: "text-h2 md:text-h1 font-bold tracking-[-0.024em]",
  h2: "text-h3 md:text-h2",
  h3: "text-h4 md:text-h3 font-bold",
  h4: "text-h5 md:text-h4",
  h5: "text-h6 md:text-h5 tracking-[-0.024em] font-black",
  h6: "text-normal md:text-h6 tracking-[-0.016em] font-bold",
}

export const regionColors: LookupTable = {
  "east-anglia": "bg-theme-regions-purple",
  "midlands-and-north-wales": "bg-theme-regions-blue",
  "north-england-inc-isle-of-man": "bg-theme-regions-red",
  "scotland-and-ni": "bg-theme-regions-green",
  "south-east-england": "bg-theme-regions-orange",
  "west-country-and-south-wales": "bg-theme-regions-lime",
}
// last 2 colours are wrong because data is wrong
