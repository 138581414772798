import React, { useContext, useEffect, useState } from "react"
import useSupplierCategories from "~/hooks/useSupplierCategories"
import { ProfileContext } from "../routes/SuppliersEdit"
import Dropdown from "./Dropdown"

type Props = {
  name: string
  placeholder: string
  value: any
}

const Categories = ({ name, placeholder, value }: Props) => {
  const context = useContext(ProfileContext)
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>()

  const [selectedSubCategory, setSelectedSubCategory] = useState<string>()
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string>()

  // get supplier categories
  const { data: categories } = useSupplierCategories()
  const { data: subCategories } = useSupplierCategories(selectedCategory)

  // check if categories is same as subCategories
  const isSame = (a: any, b: any) => {
    if (a.length !== b.length) return false
    for (let i = 0; i < a.length; i++) {
      if (a[i].id !== b[i].id) return false
    }
    return true
  }

  const noSubCategories = isSame(categories, subCategories)

  const options = categories?.map(category => ({
    value: category?.slug || "",
    label: category?.name || "",
  }))

  const subOptions = isSame(categories, subCategories)
    ? []
    : subCategories?.map(category => ({
        value: category?.slug || "",
        label: category?.name || "",
      }))

  const preSetCategories = context?.data?.supplierCategories?.nodes

  // preset category
  useEffect(() => {
    if (preSetCategories) {
      const category = preSetCategories.find((category: any) => {
        if (category.parentId) return false
        return categories.some(cat => cat.id === category.id)
      })
      if (category && !selectedCategory) {
        setSelectedCategory(category.slug)
      }
    }
  }, [preSetCategories, categories])

  // preset sub category
  useEffect(() => {
    if (preSetCategories && selectedCategory) {
      const subCategory = preSetCategories.find((category: any) => {
        if (!category.parentId) return false
        return subCategories.some(cat => cat.id === category.id)
      })
      if (subCategory && !selectedSubCategory) {
        setSelectedSubCategory(subCategory.slug)
      }
    }
  }, [preSetCategories, selectedCategory, subCategories])

  const convertNameToSubName = (name: string) => {
    // prefix name with sub to make it a subCategory, maintain PascalCase
    const subName = name
      .replace(/([A-Z])/g, "$1")
      .replace(/^./, function (str) {
        return str.toUpperCase()
      })

    return `sub${subName}`
  }

  return (
    <>
      <Dropdown
        name={name}
        placeholder={placeholder}
        options={options}
        selected={selectedCategory}
        onChange={e => setSelectedCategory(e.target.value)}
      />
      {subCategories.length > 0 && !noSubCategories && (
        <div className="relative">
          <label className={`mb-2 block text-caption font-medium`}>
            Sub Category
            {/* {field.required && <span className="text-theme-orange">*</span>} */}
          </label>
          <Dropdown
            name={convertNameToSubName(name)}
            placeholder={placeholder}
            options={subOptions}
            selected={selectedSubCategory}
          />
        </div>
      )}
    </>
  )
}

export default Categories
