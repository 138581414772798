import { useQuery, useMutation } from "@apollo/client"
import { GET_SUPPLIER, UPDATE_SUPPLIER } from "~/lib/queries"
import { useUserStore } from "~/lib/stores/user"
import { GET_USER } from "./useAuth"

export const useSupplier = (slug: string) => {
  const { data, loading, error } = useQuery(GET_SUPPLIER, {
    variables: {
      slug: slug,
    },
  })

  return {
    data: data?.supplier,
    loading,
    error,
  }
}

export const useUpdateSupplier = (
  slug?: string,
  onComplete?: (data?: any) => void
) => {
  const currentUser = useUserStore(state => state.user)

  const [updateSupplierMutation, { data, loading, error }] = useMutation(
    UPDATE_SUPPLIER,
    {
      refetchQueries: [
        {
          query: GET_SUPPLIER,
          variables: {
            slug,
          },
        },
        {
          query: GET_USER,
          variables: {
            id: currentUser?.id,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: onComplete,
    }
  )

  const updateSupplier = (variables: {}) => {
    updateSupplierMutation(variables).catch(error => {
      console.error(error)
    })
  }

  return {
    updateSupplier,
    data,
    loading,
    error,
  }
}
