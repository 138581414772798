import React, { useEffect, useState } from "react"
import { slugify } from "~/utils/helpers"
import { usePostFormStore } from "~/lib/stores/forms"

type RadiosProps = {
  name: string
  label: string
  options: [
    {
      value: boolean
      label: string
      hidden?: boolean
    }
  ]
  value: boolean
  required?: boolean
  dataField?: string
  disabled?: boolean
  loaded?: boolean
  post?: any
}

const radioValue = (value: boolean | null) => {
  // if true, return true as boolean
  if (value === true) return "true"

  // if false, return false as boolean
  if (value === false || value === null || value === undefined) return "false"
}

const Radios = ({
  name,
  label,
  options,
  value,
  disabled,
  required,
  loaded,
  post,
}: RadiosProps) => {
  if (value === null) value = false

  const [originalValue, setOriginalValue] = useState(String(value))

  const dirtyFields = usePostFormStore(state => state.dirtyFields)
  const addDirtyField = usePostFormStore(state => state.addDirtyField)
  const removeDirtyField = usePostFormStore(state => state.removeDirtyField)

  const formValues = usePostFormStore(state => state.values)

  useEffect(() => {
    if (!formValues?.newsPost) return
    setSelectedRadio(String(radioValue(formValues?.newsPost[name])))
  }, [formValues])

  const [selectedRadio, setSelectedRadio] = useState(String(value || "false"))

  // on change, update the stored fields
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSelectedRadio(value)

    // if the field is dirty, add it to the dirtyFields array

    if (!dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    if (selectedRadio === originalValue) {
      removeDirtyField(name)
    }
  }

  useEffect(() => {
    if (value) {
      setSelectedRadio(String(value))
    }
  }, [value])

  if (!loaded) return null

  return (
    <div
      className={`grid grid-cols-2 ${
        disabled ? "pointer-events-none opacity-50" : ""
      }`}
    >
      {options.map((option, index) => {
        const label = slugify(option.label.toLowerCase())

        return (
          <label
            key={index}
            htmlFor={label}
            className={`mb-8 flex items-center ${
              option.hidden ? "sr-only" : ""
            }`}
            aria-hidden={option.hidden}
          >
            <input
              id={label}
              name={name}
              type="radio"
              value={String(option.value)}
              checked={selectedRadio === String(option.value)}
              className={`h-9 w-9 accent-theme-orange`}
              readOnly={disabled}
              onChange={onChange}
            />
            <p className="ml-3 font-bold">{option.label}</p>
            <p>{value}</p>
          </label>
        )
      })}
    </div>
  )
}

export default Radios
