// /portal/suppliers/preview
import React from "react"
import parse from "html-react-parser"
import { useSupplier } from "~/hooks/supplier"
import useUser from "~/hooks/useUser"
import { bytesToSize } from "~/utils/helpers"
import fileIcon from "../../images/file-icon.png"
import Button, { ButtonLink } from "../reusable/Button"
import Page from "../reusable/Page"
import Spacer from "../reusable/svgs/Spacer"
import Wrapper from "../reusable/Wrapper"
import useImages from "~/hooks/useImages"
import PreviewImage from "../reusable/PreviewImage"
import { FaEnvelope } from "react-icons/fa"
import VideoEmbed from "../reusable/VideoEmbed"
import { PageSkeleton } from "../reusable/Skeleton"
import { Document } from "~/components/routes/{wpSupplierNews.slug}"
import PostImage from "../reusable/PostImage"

const SuppliersPreview = () => {
  const { user } = useUser()
  const slug = user?.linkedGroupData?.slug || ""
  // get current supplier data
  const { data, loading, error } = useSupplier(slug)
  const logo = useImages(data?.supplierDetails?.profilePicture?.fileName)
  const banner = useImages(data?.supplierDetails?.heroBanner?.fileName)

  if (loading && !data) return <PageSkeleton />

  const {
    title,
    supplierDetails: {
      videoUrl,
      emailAddress,
      heroBanner,
      introduction,
      MembersBenefits,
      profilePicture,
      termsAndConditions,
    },
  } = data

  const documents = data?.supplierDetails?.documents?.filter(
    item => item !== null
  )

  const fallbackImage = data?.supplierDetails?.heroBanner?.mediaItemUrl
  const fallbackLogo = data?.supplierDetails?.profilePicture?.mediaItemUrl

  return (
    <Page title={title}>
      <div className="fixed top-0 left-0 right-0 z-[2] min-h-[100px] w-full rounded-br-3xl rounded-bl-3xl bg-theme-orange p-4 py-8">
        <Wrapper
          className={`items-center justify-center px-3 max-md:space-y-6 md:justify-between`}
        >
          <h2 className="hidden text-h3 font-bold md:block md:text-h2">
            Edit your profile
          </h2>
          <ButtonLink
            link={`/portal/suppliers/edit`}
            text={`Edit Profile`}
            buttonStyle="primary"
            buttonColor="light-green"
            className="!mb-0 !mt-0"
          />
        </Wrapper>
      </div>
      <div className={`bg-theme-orange-lighter pt-8 md:pt-14`}>
        <Wrapper>
          {/* <CategoriesBreadcrumb categories={data?.supplierCategories?.nodes} /> */}
          <h1
            className={`flex-set-0-0 mb-6 basis-full px-3 text-h2 font-bold md:text-h1 lg:mx-auto lg:basis-5/6`}
          >
            {data?.title}
          </h1>
        </Wrapper>
        <Spacer top={`orange-lighter`} bottom={`yellow`} />
      </div>
      <div className={`banner mt-[-90px] bg-theme-yellow pb-10`}>
        <Wrapper className={`md:flex-row-reverse`}>
          <div
            className={`supp-brand flex-set-0-0 max-w-full basis-full place-self-center px-3 md:max-w-1/3 md:basis-1/3 lg:mx-16 lg:max-w-1/4 lg:basis-1/4`}
          >
            {fallbackLogo && (
              <div
                className={`logo mx-auto mb-8 flex aspect-square max-w-[380px] items-center justify-center rounded-full border border-theme-gray-mid bg-theme-white p-6`}
              >
                <PostImage
                  alt={logo?.altText || data?.title}
                  fallbackUrl={fallbackLogo}
                  className={`max-w-[205px]`}
                />
              </div>
            )}
            {data?.supplierDetails?.emailAddress && (
              <div className="text-center">
                <h5 className={`mb-2 text-h6 font-black md:text-h5`}>
                  Contact us
                </h5>
                <a
                  href={`mailto:${data?.supplierDetails?.emailAddress}`}
                  className={`flex items-center justify-center text-normal font-bold`}
                >
                  <i className={`mr-2 flex-shrink-0 text-theme-orange`}>
                    <FaEnvelope />
                  </i>
                  {data?.supplierDetails?.emailAddress}
                </a>
              </div>
            )}
          </div>
          {fallbackImage && (
            <div
              className={`banner flex-set-0-0 max-w-full basis-full px-3 md:max-w-2/3 md:basis-2/3 lg:max-w-7/12 lg:basis-7/12`}
            >
              <PostImage
                alt={banner?.altText || data?.title}
                fallbackUrl={fallbackImage}
                className={`vs-shadow overflow-hidden rounded-[32px]`}
              />
            </div>
          )}
        </Wrapper>
      </div>
      <div className={`bbl-post-wrap-block bg-theme-yellow pb-10`}>
        <Wrapper className={`relative`}>
          <div
            className={`wrap flex-set-0-0 mx-auto basis-full md:mx-1/12 md:basis-5/6 lg:mx-1/6 lg:basis-2/3 [&_p]:mb-8 [&_p]:leading-8 [&_.bd-core-heading-block]:mb-8 [&_.gatsby-caption-wrapper]:mx-auto [&_.gatsby-caption-wrapper]:mb-8 [&_.gatsby-caption-wrapper]:max-w-almost-full [&_.wrapper]:w-full [&_.bd-video-block]:my-14 [&_.bd-button-group-block]:mb-8 [&_ul]:mb-8 [&_ol]:mb-8`}
          >
            {data?.supplierDetails?.memberBenefits !== null && (
              <div
                className={`benefit mb-8 md:mb-10 [&_p]:text-normal [&_p]:leading-loose`}
              >
                <h3 className={`mb-6 text-h4 font-bold md:text-h3`}>
                  Member Benefit
                </h3>
                {parse(data?.supplierDetails?.memberBenefits)}
              </div>
            )}
            {/* {data?.supplierDetails?.videoUrl && (
              <VideoEmbed url={data?.supplierDetails?.videoUrl || ""} />
            )} */}
            {data?.supplierDetails?.introduction !== null && (
              <div
                className={`intro mb-8 md:mb-10 [&_p]:text-normal [&_p]:leading-loose`}
              >
                <h5 className={`mb-6 text-h6 font-bold md:text-h5`}>
                  Introduction
                </h5>
                {parse(data?.supplierDetails?.introduction)}
              </div>
            )}
            {data?.supplierDetails?.description !== null && (
              <div
                className={`desc mb-8 md:mb-10 [&_p]:text-normal [&_p]:leading-loose`}
              >
                <h5 className={`mb-6 text-h6 font-bold md:text-h5`}>
                  Description
                </h5>
                {parse(data?.supplierDetails?.description)}
              </div>
            )}
            {videoUrl && <VideoEmbed url={videoUrl} />}
            {documents?.length > 0 && documents && (
              <div
                className={`mb-10 rounded-3xl bg-theme-white p-10 md:mb-[72px]`}
              >
                <h5
                  className={`mb-5 text-h6 font-black tracking-[-0.024em] md:text-h5`}
                >
                  Documents
                </h5>
                {documents.map((file: any, index: number) => (
                  <Document key={`file-${index}`} file={file} />
                ))}
              </div>
            )}
            {termsAndConditions && (
              <div
                className={`rounded-3xl bg-theme-gray-light p-10 [&_p]:mb-0 [&_p]:leading-6 [&_p]:tracking-normal`}
              >
                <h5
                  className={`mb-5 text-h6 font-black tracking-[-0.024em] md:text-h5`}
                >
                  Terms & Conditions
                </h5>
                <div className={`mb-0 text-small`}>
                  {parse(termsAndConditions)}
                </div>
              </div>
            )}
          </div>
        </Wrapper>
      </div>
      <Spacer top="yellow" bottom="black" />
    </Page>
  )
}

export default SuppliersPreview
