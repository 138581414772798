import React from "react"
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa"
import Link from "./Link"
import dots from "~/images/orange-dots.png"
import { regionColors } from "~/styles/lookups"
import clsx from "clsx"

type Props = {
  accountManager: {
    forename: string
    surname: string
    telephone: string
    emailAddress: string
    thumbnail: string
    region: string
  }
  region: string
}

const AccountManagerCard = ({ accountManager, region }: Props) => {
  if (!accountManager) return null

  return (
    <>
      <div
        className={`account-manager relative mx-auto basis-almost-full md:basis-1/2 lg:basis-5/12`}
      >
        <img
          src={dots}
          className={`absolute top-[-60px] left-[20px] z-[1] w-[328px]`}
        />
        <div
          className={`vs-shadow relative z-[2] mx-auto w-full max-w-[318px] overflow-hidden rounded-[32px] bg-theme-white`}
        >
          <div
            className={clsx(
              `image block h-[142px]`,
              accountManager.region
                ? regionColors[accountManager.region]
                : "bg-theme-green"
            )}
          ></div>
          <img
            src={accountManager?.thumbnail || `https://via.placeholder.com/220`}
            className={`mx-auto mt-[-110px] h-[220px] w-[220px] rounded-full`}
          />
          <h4
            className={`block px-4 pt-6 pb-8 text-center text-h5 md:text-h5`}
          >{`${accountManager?.forename} ${accountManager?.surname}`}</h4>
          <div
            className={`grid grid-cols-1 overflow-hidden rounded-[32px] border border-theme-gray-light`}
          >
            <Link
              to={`tel:${accountManager?.telephone}`}
              activeClassName={undefined}
              forceBlank={undefined}
              className={`flex items-center justify-center gap-6 py-5 px-3 text-center font-bold transition hover:bg-gray-50`}
            >
              <div
                className={`h-6 w-6 text-theme-orange [&_svg]:h-6 [&_svg]:w-6`}
              >
                <FaPhoneAlt />
              </div>
              {accountManager?.telephone}
            </Link>
            <Link
              to={`mailto:${accountManager?.emailAddress}`}
              activeClassName={undefined}
              forceBlank={undefined}
              className={`flex items-center justify-center gap-6 border-t border-theme-gray-light py-5 px-3 text-center font-bold transition hover:bg-gray-50`}
            >
              <div
                className={`h-6 w-6 text-theme-orange [&_svg]:h-6 [&_svg]:w-6`}
              >
                <FaEnvelope />
              </div>
              Email Me
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountManagerCard
