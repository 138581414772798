import { graphql, useStaticQuery } from "gatsby"

const useRegions = () => {
  const data = useStaticQuery(graphql`
    query getRegions {
      allWpRegion {
        edges {
          node {
            id
            name
            details {
              postcodes
            }
          }
        }
      }
    }
  `)

  return data?.allWpRegion?.edges?.map((edge: any) => ({
    id: edge.node.id,
    name: edge.node.name,
    ...edge.node.details,
  }))
}

export default useRegions
