import React, { useState } from "react"
import { FormFields } from "~/components/formFields/GravityForms"
import Button from "~/components/reusable/Button"

interface Props {
  register: any
  fieldSets: any
}

const SteppedForm = ({ register, fieldSets }: Props) => {
  const [activeStep, setActiveStep] = useState(fieldSets[0].id)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")

  const handleNextStep = () => {
    if (activeStep === fieldSets[0].id) {
      setActiveStep(fieldSets[1].id)
    }
    if (activeStep === fieldSets[1].id) {
      setActiveStep(fieldSets[2].id)
    }
  }

  const handlePrevStep = () => {
    if (activeStep === fieldSets[1].id) {
      setActiveStep(fieldSets[0].id)
    }
    if (activeStep === fieldSets[2].id) {
      setActiveStep(fieldSets[1].id)
    }
  }

  // check if last step
  const isLastStep = activeStep === fieldSets[fieldSets.length - 1].id

  // handle step change
  const handleStepChange = (step: string) => {
    if (
      activeStep === fieldSets[0].id ||
      (activeStep === fieldSets[1].id && step === fieldSets[2].id)
    )
      return null
    setActiveStep(step)
  }

  return (
    <>
      <div className="my-4 flex justify-around border-b-4 border-theme-green-light max-sm:-mx-8 md:my-8">
        {fieldSets.map(({ label, id }) => (
          <div
            key={id}
            className={`relative block rounded-t-[24px] px-4 py-4 text-left font-bold uppercase tracking-[0.15em] sm:rounded-t-[32px] sm:px-8 sm:text-normal lg:px-12 ${
              activeStep === id
                ? "-mb-1 border-b-4 border-theme-green bg-theme-white text-theme-black"
                : "text-theme-gray"
            }`}
            onClick={() => handleStepChange(id)}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="mx-auto max-w-[432px] pt-10">
        <>
          {fieldSets.map(({ label, description, id, fields }: any) => (
            <fieldset
              key={id}
              className={`pt-4 ${activeStep === id ? "block" : "hidden"}`}
            >
              <h2 className="mb-10 text-h3 md:text-h2">
                {description || label}
              </h2>

              {fields &&
                fields.map((field: any) => {
                  const Field = FormFields[
                    field.type as keyof typeof FormFields
                  ] as React.ElementType
                  return (
                    <Field key={field?.id} {...field} register={register} />
                  )
                })}

              <div className="mx-auto flex max-w-[432px] justify-between [&_.brew-button]:mr-0">
                <div className="font-semibold">
                  <span className="text-theme-orange">*</span> Required Fields
                </div>
                <div>
                  {activeStep !== fieldSets[0].id && (
                    <button
                      className="mr-8 font-bold underline hover:no-underline"
                      onClick={handlePrevStep}
                    >
                      Back
                    </button>
                  )}
                  {!isLastStep ? (
                    <Button
                      buttonColor="orange"
                      text="Next"
                      buttonStyle="primary"
                      type="button"
                      onClick={handleNextStep}
                    />
                  ) : (
                    <Button
                      buttonColor="orange"
                      text="Submit"
                      buttonStyle="primary"
                      type="submit"
                    />
                  )}
                </div>
              </div>
            </fieldset>
          ))}
        </>
      </div>
    </>
  )
}

export default SteppedForm
