import React, { useEffect } from "react"
import SlideArrow from "~/components/reusable/svgs/SlideArrow"

export default function Pagination({
  activePage,
  setActivePage,
  paginationPages,
  scrollToTarget,
}: any) {
  const [pagesToShow, setPagesToShow] = React.useState<number[]>([])

  const handleActivePage = (page: number) => {
    setActivePage(page)
    // scroll into view
    scrollToTarget?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })

    window.history.pushState({}, "", `?p=${page}`)
  }

  const pagesOfItems = Array.from(Array(paginationPages).keys())
  const firstPage = pagesOfItems[0]
  const lastPage = pagesOfItems[pagesOfItems.length - 1]

  const totalPages = pagesOfItems.length
  const totalPageRange = 4

  useEffect(() => {
    if (activePage - 1 === firstPage) {
      setPagesToShow(pagesOfItems.slice(0, 4))
    } else if (activePage - 1 === lastPage) {
      setPagesToShow(pagesOfItems.slice(-4))
    } else {
      setPagesToShow(pagesOfItems.slice(activePage - 2, activePage + 2))
    }
  }, [activePage, setPagesToShow])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const page = urlParams.get("p")
    if (page) {
      handleActivePage(parseInt(page))
    }
  }, [])

  if (paginationPages <= 1) return null

  return (
    <ul
      className={`flex-set-0-0 mx-auto flex basis-almost-full items-center justify-center md:mt-10`}
    >
      <li className={``}>
        <button
          onClick={() => handleActivePage(activePage - 1)}
          className={`mr-6 block h-12 w-12 rotate-180 md:mr-14 ${
            activePage === 1 ? `cursor-not-allowed opacity-25` : ``
          }`}
        >
          <SlideArrow />
        </button>
      </li>
      {/* first page */}
      {activePage >= firstPage + 3 && totalPages > totalPageRange ? (
        <>
          <li>
            <button
              className={`px-3 text-normal font-bold md:px-6 ${
                firstPage + 1 === activePage
                  ? `text-theme-gray-mid underline`
                  : `text-theme-black`
              }`}
              onClick={() => handleActivePage(firstPage + 1)}
            >
              {firstPage + 1}
            </button>
          </li>
          {activePage >= firstPage + 4 && <li>. . . </li>}
        </>
      ) : null}

      {/* current page range */}
      {pagesToShow.map((p, index) => {
        if (p === lastPage && activePage <= lastPage - 3) return null
        return (
          <li key={p}>
            <button
              className={`px-3 text-normal font-bold md:px-6 ${
                p + 1 === activePage
                  ? `text-theme-gray-mid underline`
                  : `text-theme-black`
              }`}
              onClick={() => handleActivePage(p + 1)}
            >
              {p + 1}
            </button>
          </li>
        )
      })}

      {/* last page */}
      {activePage <= lastPage - 2 ? (
        <>
          {activePage <= lastPage - 3 && <li>. . . </li>}
          <li>
            <button
              className={`px-3 text-normal font-bold md:px-6 ${
                lastPage + 1 === activePage
                  ? `text-theme-gray-mid underline`
                  : `text-theme-black`
              }`}
              onClick={() => handleActivePage(lastPage + 1)}
            >
              {lastPage + 1}
            </button>
          </li>
        </>
      ) : null}
      <li>
        <button
          onClick={() => handleActivePage(activePage + 1)}
          className={`ml-6 block h-12 w-12 md:ml-14 ${
            activePage === paginationPages
              ? `cursor-not-allowed opacity-25`
              : ``
          }`}
        >
          <SlideArrow />
        </button>
      </li>
    </ul>
  )
}
