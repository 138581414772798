import clsx from "clsx"
import React, { useEffect, useState } from "react"
import { usePostFormStore } from "~/lib/stores/forms"

type Props = {
  name: string
  type: string | (string & {}) | undefined
  value: string | number | readonly string[] | undefined
  required?: boolean | undefined
  disabled?: boolean | undefined
  className?: string | undefined
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
}

const TextInput = ({
  name,
  type,
  required,
  value,
  disabled,
  className,
  onChange,
}: Props) => {
  const [originalValue, setOriginalValue] = useState(value)
  const { dirtyFields, addDirtyField, removeDirtyField } = usePostFormStore()

  useEffect(() => {
    setOriginalValue(value)
  }, [value])

  // on change, update the stored fields
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e)
    }
    const { value } = e.target
    

    // if the field is dirty, add it to the dirtyFields array
    if (value !== "" && !dirtyFields.includes(name)) {
      addDirtyField(name)
    }

    // if the field is clean, remove it from the dirtyFields array
    if (value === "" && dirtyFields.includes(name)) {
      removeDirtyField(name)
    }

    // if the field is clean and the original value is not empty, add it to the dirtyFields array
    if (value === "" && originalValue !== "") {
      addDirtyField(name)
    }

    // if value is same as original value, remove it from the dirtyFields array
    if (value === originalValue) {
      removeDirtyField(name)
    }
  }

  return (
    <input
      id={name}
      className={clsx(
        `input rounded-4 vs-shadow-light mb-7 border-none p-5 text-caption text-theme-gray-mid`,
        className
      )}
      type={type}
      name={name}
      required={required}
      defaultValue={value}
      disabled={disabled}
      pattern={type === "tel" ? "[0-9]*" : undefined}
      onChange={onChangeHandler}
    />
  )
}

export default TextInput
