import React from "react"

const Dot = () => {
  return (
    <div className="absolute top-0 left-0 -z-10 min-h-screen w-screen overflow-hidden">
      <div
        className={`dot absolute right-[-280px] top-[-280px] z-[-1] block h-[550px] w-[550px] rounded-full bg-theme-green transition-all md:h-[640px] md:w-[640px] lg:h-[1058px] lg:w-[1058px]`}
      />
    </div>
  )
}

export default Dot
