import React, { useState } from "react"
import { navigate } from "gatsby"
import { useMutation } from "@apollo/client"
import Layout from "~/components/Layout"
import Wrapper from "~/components/reusable/Wrapper"
import PreviewImage from "~/components/reusable/PreviewImage"
import useTemplateContent from "~/hooks/useTemplateContent"
import { SUBMIT_FORM } from "~/components/formFields/GravityForms"
import GravityForm from "~/components/reusable/GravityForm"
import { isBrowser } from "~/utils/helpers"
import Spacer from "~/components/reusable/svgs/Spacer"
import useUser from "~/hooks/useUser"
import Dot from "../reusable/Dot"

export default function Callback({ path }: { path: string }) {
  if (!isBrowser()) {
    return null
  }
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [emailAddress, setEmailAddress] = useState("")
  const [postcode, setPostcode] = useState("")

  const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM, {
    onCompleted: data => {
      setFormSubmitted(true)
      navigate("thank-you", {
        state: {
          formSubmitted: true,
        },
      })
    },
    onError: error => {
      console.error(error)
      setFormSubmitted(true)
      navigate("thank-you", {
        state: {
          formSubmitted: true,
        },
      })
    }
  })

  const { user } = useUser()

  const suppliersContent = useTemplateContent("supplierPortal")
  const membersContent = useTemplateContent("membersCallback")

  //check if path contains /supplier or /members
  const isSupplier = path.includes("suppliers")
  const isMember = path.includes("members")

  let formId, content

  if (isSupplier) {
    formId = useTemplateContent("supplierPortal").callbackForm
    content = suppliersContent
  } else {
    formId = useTemplateContent("memberPortal")?.callbackForm
    content = membersContent
  }

  const prepopulatedFields = [
    {
      id: "businessName",
      value: user?.linkedGroupData?.title,
    },
    {
      id: "postcode",
      value: user?.linkedGroupData?.memberDetails?.postcode,
    },
    {
      id: "emailAddress",
      value: user?.email,
    },
    {
      id: "yourName",
      value: user?.name,
    },
  ]

  return (
    <>
      <Wrapper className={``}>
        <Dot />
        <h1 className="flex-set-0-0 lg: mx-auto basis-full px-3 py-10 text-h2 font-bold md:pt-[72px] md:pb-14 md:text-h1 lg:basis-5/6">
          {content?.pageTitle}
        </h1>

        <div
          className={`flex-set-0-0 vs-shadow relative z-[2] mx-auto basis-almost-full rounded-[32px] bg-theme-green-lighter p-8 md:px-24 md:py-16 lg:basis-almost-5/6`}
        >
          {!formSubmitted ? (
            <header>
              <h2 className={`mb-2 text-h4 font-bold md:text-h3`}>
                {content?.title}
              </h2>
              <p className={`mb-10 text-small md:mb-16`}>{content?.text}</p>
            </header>
          ) : null}
          {formId && (
            <GravityForm
              formId={formId}
              submitFunction={submitForm}
              loading={loading}
              setEmailAddress={setEmailAddress}
              setPostcode={setPostcode}
              prepopulatedFields={prepopulatedFields}
              formSubmitted={formSubmitted}
            />
          )}
        </div>
      </Wrapper>

      <div className={`mt-[-90px]`}>
        <Spacer top={`transparent`} bottom={`black`} />
      </div>
    </>
  )
}
