import React from "react"
import parse from "html-react-parser"
import { bytesToSize } from "~/utils/helpers"
import useSupplier from "~/hooks/useSupplier"
import EditSupplierPost from "~/components/routes/EditSupplierPost"
import PreviewImage from "~/components/reusable/PreviewImage"
import Wrapper from "~/components/reusable/Wrapper"
import Spacer from "~/components/reusable/svgs/Spacer"
import SocialShare from "~/components/reusable/SocialShare"
import fileIcon from "../../images/file-icon.png"
import Page from "../reusable/Page"
import Link from "~/components/Link"
import dots from "../../images/orange-dots.png"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import Button from "~/components/reusable/Button"
import AccountManagerCard from "../AccountManagerCard"
import useAccountManager from "~/hooks/useAccountManager"
import LinkedAccountManager from "../LinkedAccountManager"
import useUser from "~/hooks/useUser"
import useImages from "~/hooks/useImages"
import VideoEmbed from "../reusable/VideoEmbed"
import { membersPortal } from "~/lib/constants"
import { PageSkeleton } from "../reusable/Skeleton"

type Props = {
  slug: string
  path: string
}

export default function SingleSupplier({ slug, path }: Props) {
  const { user } = useUser()
  const { data, loading, error } = useSupplier(slug)

  const userPostcode = user?.linkedGroupData?.memberDetails?.postcode

  if (loading) return <PageSkeleton />

  const logo = useImages(data?.supplierDetails?.profilePicture?.fileName)
  const banner = useImages(data?.supplierDetails?.heroBanner?.fileName)

  return (
    <Page title={data?.title || ""}>
      <div className={`bg-theme-orange-lighter pt-8 md:pt-14`}>
        <Wrapper>
          <CategoriesBreadcrumb categories={data?.supplierCategories?.nodes} />
          <h1
            className={`flex-set-0-0 mb-6 basis-full px-3 text-h2 font-bold md:text-h1 lg:mx-auto lg:basis-5/6`}
          >
            {data?.title}
          </h1>
        </Wrapper>
        <Spacer top={`orange-lighter`} bottom={`yellow`} />
      </div>
      <div className={`banner mt-[-90px] bg-theme-yellow pb-10`}>
        <Wrapper className={`md:flex-row-reverse`}>
          <div
            className={`supp-brand flex-set-0-0 md:maxc-w-1/3 max-w-full basis-full px-3 md:basis-1/3 lg:mr-1/12 lg:max-w-1/4 lg:basis-1/4`}
          >
            {logo && (
              <div
                className={`logo mx-auto mb-8 flex aspect-square max-w-[380px] items-center justify-center rounded-full border border-theme-gray-mid bg-theme-white p-6`}
              >
                <PreviewImage
                  alt={logo?.altText || data?.title}
                  fallbackUrl={
                    logo?.publicURL ||
                    data?.supplierDetails?.profilePicture?.mediaItemUrl
                  }
                  image={logo}
                  className={`max-w-[205px]`}
                />
              </div>
            )}
            {data?.supplierDetails?.emailAddress !== null && (
              <>
                <h5 className={`mb-2 text-h6 font-black md:text-h5`}>
                  Contact us
                </h5>
                <a
                  href={`mailto:${data?.supplierDetails?.emailAddress}`}
                  className={`flex items-center text-normal font-bold`}
                >
                  <i className={`mr-2 text-theme-orange`}>
                    <FaEnvelope />
                  </i>
                  {data?.supplierDetails?.emailAddress}
                </a>
              </>
            )}
          </div>
          {banner && (
            <div
              className={`banner flex-set-0-0 max-w-full basis-full px-3 md:max-w-2/3 md:basis-2/3 lg:max-w-7/12 lg:basis-7/12`}
            >
              <PreviewImage
                alt={banner?.altText || data?.title}
                fallbackUrl={
                  banner?.publicUrl ||
                  data?.supplierDetails?.heroBanner?.mediaItemUrl
                }
                image={banner}
                className={`vs-shadow overflow-hidden rounded-[32px]`}
              />
            </div>
          )}
        </Wrapper>
      </div>
      <div className={`bbl-post-wrap-block bg-theme-yellow pb-10`}>
        <Wrapper className={`relative`}>
          <div
            className={`prose wrap flex-set-0-0 mx-auto basis-full md:mx-1/12 md:basis-5/6 lg:mx-1/6 lg:basis-2/3 [&_.bd-core-heading-block]:mb-8 [&_.gatsby-caption-wrapper]:mx-auto [&_.gatsby-caption-wrapper]:mb-8 [&_.gatsby-caption-wrapper]:max-w-almost-full [&_p]:mb-8 [&_p]:leading-8 [&_.wrapper]:w-full [&_.bd-video-block]:my-14 [&_.bd-button-group-block]:mb-8 [&_ul]:mb-8 [&_ol]:mb-8`}
          >
            {data?.supplierDetails?.memberBenefits !== null && (
              <div
                className={`benefit mb-8 md:mb-10 [&_p]:text-normal [&_p]:leading-loose`}
              >
                <h3 className={`mb-6 text-h4 font-bold md:text-h3`}>
                  Member Benefit
                </h3>
                {parse(data?.supplierDetails?.memberBenefits)}
              </div>
            )}
            {data?.supplierDetails?.videoUrl && (
              <VideoEmbed url={data?.supplierDetails?.videoUrl || ""} />
            )}
            {data?.supplierDetails?.introduction !== null && (
              <div
                className={`intro mb-8 md:mb-10 [&_p]:text-normal [&_p]:leading-loose`}
              >
                <h5 className={`mb-6 text-h6 font-bold md:text-h5`}>
                  Introduction
                </h5>
                {parse(data?.supplierDetails?.introduction)}
              </div>
            )}
            {data?.supplierDetails?.description !== null && (
              <div
                className={`desc mb-8 md:mb-10 [&_p]:text-normal [&_p]:leading-loose`}
              >
                <h5 className={`mb-6 text-h6 font-bold md:text-h5`}>
                  Description
                </h5>
                {parse(data?.supplierDetails?.description)}
              </div>
            )}
            {data?.supplierDetails?.documents && (
              <div
                className={`mb-10 rounded-3xl bg-theme-white p-10 md:mb-[72px]`}
              >
                <h5
                  className={`mb-2 text-h6 font-black tracking-[-0.024em] md:text-h5`}
                >
                  Documents
                </h5>
                {data?.supplierDetails?.documents?.map(
                  (doc: any, index: number) => (
                    <Link
                      key={index}
                      to={doc?.file?.mediaItemUrl}
                      className={`mx-auto flex w-3/4 items-center rounded-lg p-3 transition hover:bg-theme-orange-lighter/50 [&_p]:mb-0 [&_p]:leading-normal [&_p]:tracking-normal`}
                      activeClassName={undefined}
                      forceBlank={undefined}
                    >
                      <div className={`icon relative w-10`}>
                        <img src={fileIcon} className={`w-10`} />
                        <span
                          className={`absolute bottom-2 left-1 right-1 text-center text-[12px] font-bold uppercase tracking-wider text-theme-orange`}
                        >
                          {doc?.file?.mediaItemUrl
                            ? doc?.file?.mediaItemUrl.substr(
                                doc?.file?.mediaItemUrl.lastIndexOf(".") + 1
                              )
                            : ``}
                        </span>
                      </div>
                      <div className={`flex-1 px-8`}>
                        <p className={`text-small font-bold`}>
                          {doc?.title ? doc?.title : doc?.file?.fileName}
                        </p>
                        <p className={`text-caption text-theme-gray-mid`}>
                          {bytesToSize(doc?.file?.fileSize)}
                        </p>
                      </div>
                    </Link>
                  )
                )}
              </div>
            )}
            {data?.supplierDetails?.termsAndConditions !== null && (
              <div
                className={`rounded-3xl bg-theme-gray-light p-6 [&_p]:mb-4 [&_p]:[&_p]:text-small [&_p]:leading-6 [&_p]:tracking-normal`}
              >
                <h5
                  className={`mb-5 text-h6 font-black tracking-[-0.024em] md:text-h5`}
                >
                  Terms &amp; Conditions
                </h5>
                {parse(data?.supplierDetails?.termsAndConditions)}
              </div>
            )}
          </div>
        </Wrapper>
      </div>
      <div className={`relative z-[2]`}>
        <Spacer top="yellow" bottom="black" />
      </div>
    </Page>
  )
}

const CategoriesBreadcrumb = ({ categories }: { categories: any }) => {
  if (!categories) return null

  // sort categories no parentId is first
  const sortedCategories = [...categories].sort((a: any, b: any) => {
    if (a.parentId === null && b.parentId !== null) return -1
    if (a.parentId !== null && b.parentId === null) return 1
    return 0
  })

  return (
    <div
      className={`breadcrumb flex-set-0-0 mb-2 basis-full px-3 text-caption font-extrabold uppercase tracking-[0.2em] text-theme-gray-mid lg:mx-auto lg:basis-5/6`}
    >
      {sortedCategories.map((category: any, index: number) => {
        if (index === 0) {
          return (
            <Link
              key={category.id}
              to={`${membersPortal}/benefits/${category.slug}`}
            >
              {category.name}
            </Link>
          )
        }
        return (
          <span key={index}>
            {index !== 0 ? ` / ` : ``}
            {category?.name}
          </span>
        )
      })}
    </div>
  )
}
